import React, { useEffect, useRef, useState } from 'react';
import { UserCircleIcon, CheckIcon, HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid';
import * as Sentry from "@sentry/react";

const reasons = [
  "This is harmful/unsafe",
  "This isn't helpful",
  "This is untrue",
  "This was falsely flagged"
];


const LoadingSpinner = ({ colors }) => (

  <div className="text-center">

      <div role="status">
          <svg aria-hidden="true" 
                  className={`inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 ${colors}`} 

                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span className="sr-only">Loading...</span>
      </div>
  </div>
);

const FeedbackCard = ({ user, onClose, conversationId, feedbackType }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState(new Set());
  const cardRef = useRef();
  const headerStyle = feedbackType === 'positive' ? 'text-blue-600 dark:text-blue-600' : 'text-red-600 dark:text-red-600';
  const IconComponent = feedbackType === 'positive' ? HandThumbUpIcon : HandThumbDownIcon;
  const placeholderText = feedbackType === 'positive' ? 'What do you like about the response?' : 'What was the issue with the response? How could it be improved?';
  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);


    useEffect(() => {
      let timer;
      if (isSuccess) {
        timer = setTimeout(() => {
          onClose();  // Close the form after 5 seconds
        }, 5000);  // 5000 milliseconds = 5 seconds
      }
      return () => clearTimeout(timer);  // Cleanup the timer
    }, [isSuccess, onClose]);

  const handleReasonChange = (reason) => {
    const newReasons = new Set(selectedReasons);
    if (newReasons.has(reason)) {
      newReasons.delete(reason);
    } else {
      newReasons.add(reason);
    }
    setSelectedReasons(newReasons);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    // Check if the user object and necessary attributes exist
    if (!user) {
      console.error("User details are not available.");
      setIsLoading(false);
      return; // Exit the function if user details are not available
    }
  
    const reasonsText = Array.from(selectedReasons).join(', ');
    const fullMessage = `${message} - Reasons: ${reasonsText} - User: ${user.username} - ConversationId: ${conversationId}`;
    const eventId = Sentry.captureMessage("User Feedback");
    // const eventId = Sentry.lastEventId();

    const userFeedback = {
        event_id: eventId,
        name: (user.attributes.given_name || '') + ' ' + (user.attributes.family_name || ''),
        email: user.attributes.email,
        comments: fullMessage,
      };
      try {
        Sentry.captureUserFeedback(userFeedback);
        console.log("Feedback sent successfully");
  
        // Introduce a delay before stopping the loading state and showing success
        setTimeout(() => {
            setIsSuccess(true);  // Set success to true only if submission is successful
            setIsLoading(false);  // Stop the loading spinner after 3 seconds
        }, 1000);  // 3000 milliseconds = 3 seconds
  
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setIsLoading(false);  // Stop the loading spinner immediately on error
    }
    // Remove the final setLoading call from here, as it's now inside the timeout
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/30">
      <div ref={cardRef} className="w-96 rounded-lg border border-slate-200 bg-white px-3 py-6 shadow dark:border-slate-700 dark:bg-slate-900">
        <form onSubmit={handleSubmit} className={`max-w-sm `}>
          <div className="flex items-center justify-start">
            <IconComponent className={` ${headerStyle} h-6 w-6 `} />
            <h2 className="ml-2">Provide additional feedback</h2>
          </div>
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-4 w-full rounded border p-2 text-sm"
            placeholder={placeholderText}
            required
          />
        {feedbackType === 'negative' && (
            <div className="mt-4 text-sm">
              <p className="text-sm">Please select a reason:</p>
              {reasons.map((reason, index) => (
                <label key={index} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedReasons.has(reason)}
                    onChange={() => handleReasonChange(reason)}
                  />
                  <span>{reason}</span>
                </label>
              ))}
            </div>
          )}
          <button
              type="submit"
              className="mt-6 w-full rounded-lg border border-slate-300 p-4 text-center text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-blue-600 hover:text-slate-50 focus:outline-none dark:border-slate-700 dark:text-slate-200"
              disabled={isLoading || isSuccess}
          >
              {isLoading && <span className="flex items-center justify-center">
                 <LoadingSpinner colors="fill-slate-700 dark:fill-slate-200" />
                 </span>}

              {isSuccess && !isLoading && 
              <span className="flex items-center justify-center"> 
                <CheckIcon className="ml-2 h-5 w-5" />
                </span>}

              {!isLoading && !isSuccess && 'Submit Feedback'}
          </button>

        </form>
      </div>
    </div>
  );
};

export default FeedbackCard;