// src/components/utilities/klaviyoClient.js

// Function to identify a user with Klaviyo, accepting additional properties
export const identifyKlaviyoUser = (user, additionalProperties = {}) => {
console.log(user, additionalProperties)
  window._klOnsite = window._klOnsite || [];
  const userProfile = {
    $email: user.attributes.email,
    $first_name: user.attributes.given_name,
    $last_name: user.attributes.family_name,
    ...additionalProperties, // Spread additional properties
  };
  window._klOnsite.push(['identify', userProfile]);
};

// Function to track an event with Klaviyo, accepting detailed properties
export const trackKlaviyoEvent = (eventName, eventProperties = {}) => {
console.log(eventName, eventProperties)
  window._klOnsite = window._klOnsite || [];
  window._klOnsite.push(['trackEvent', eventName, eventProperties]);
};
