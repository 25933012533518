import React, { useState } from 'react';

const Alert = ({ type, title, message }) => {

    const [show, setShow] = useState(true);


  let borderColor;
  switch (type) {
    case 'success':
      borderColor = 'border-green-600';
      break;
    case 'warning':
      borderColor = 'border-amber-600';
      break;
    case 'error':
      borderColor = 'border-red-600';
      break;
    default:
      borderColor = 'border-gray-600';
  }

  const handleDismiss = () => {
    setShow(false);
  };

  if (!show) return null;

  return (
    <div
      role="alert"
      className={`flex w-full max-w-md items-start absolute z-[999] justify-between rounded-r-xl border-l-4 ${borderColor} bg-slate-50 p-4 text-slate-800 shadow-xl dark:bg-slate-800 dark:text-slate-50`}
    >
      <div className="flex items-start gap-4">
        <div className="flex-1">
          <strong className="block font-medium">{title}</strong>
          <p className="mt-2 text-sm text-slate-500 dark:text-slate-400">
            {message}
          </p>
        </div>
      </div>
      <button
        className="text-slate-500 transition hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-500"
        onClick={handleDismiss}

      >
        <span className="sr-only">Dismiss popup</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
};

export default Alert;
