import React, { useEffect, useState, useRef } from 'react';
import { ClipboardIcon, CheckIcon, HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
// import {dark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { dark, darcula, docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Highlight from 'react-highlight'
import '../devibeans.min.css';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';

import FeedbackCard from './FeedbackCard';
import UpgradeButton from './UpgradeButton';
import Markdown from 'react-markdown';


const MessageList = ({ user, conversationId, messages, theme, handleCopyClick, copiedIndex, showCopySuccess, handleToggleMenu, status, overLimit, isPricingPlansVisible, handleTogglePricingPlans }) => {
  const messagesEndRef = useRef(null);

  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);
  const [feedbackType, setFeedbackType] = useState('positive'); // 'positive' or 'negative'

  const toggleFeedback = (type) => {
    setFeedbackType(type);
    setIsFeedbackVisible(!isFeedbackVisible);
  };

  useEffect(() => {
    if (status !== 'loading' && messagesEndRef.current) {
      // Scroll into view when the status is not 'loading'
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [status, messages]);

  useEffect(() => {
    if (status === 'loading' && messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100); // Add a slight delay to ensure the element is rendered
    }
  }, [status]);
  

  function animateString(str, delayBase) {
    let array = str.split("").map((char, i) => (
      <span
        style={{ animationDelay: `${delayBase * i}s` }}
        className="animatedChar"
      >
        {char}
      </span>
    ));
    array.push(
      <img
        style={{ animationDelay: `${delayBase * str.length + 0.5}s` }}
        alt="KosherGPT Logo"
        className="animatedCharImg"
        src="/logo192.png"
      />
    );
    return array;
  }

    // Function to render messages with Markdown
    // const renderMarkdownMessage = (text) => {
    //   return <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>;
    // };

    // Function to render messages with Markdown
    const renderMarkdownMessage = (text) => {
        return <Markdown
          children={text}
          components={{
            code(props) {
              const { children, className, node, ...rest } = props;
              const match = /language-(\w+)/.exec(className || '');
              return match ? (
                <div 
                className="bg-black rounded-md max-w-xs sm:max-w-md md:max-w-lg lg:max-w-4xl"
                >
                <CodeHeader 
                    language={match[1]} 
                    onCopy={handleCopyClick} 
                    children={children} 
                    />
                  <Highlight
                    {...rest}
                    PreTag="div"
                    children={String(children).replace(/\n$/, '')}
                    language={match[1]}
                    className={`tp-4 overflow-y-auto text-sm bg-black rounded-b-md language-${
                        match[1] || "plaintext"
                      }`}
                  />
                </div>
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              )
            }
          }}
        />;
      };
      

      const CodeHeader = ({ language, onCopy, children }) => {
        return (
          <div className="flex items-center justify-between bg-gray-800 text-gray-200 px-4 py-2 rounded-t-md">
            <span 
            className='flex items-center relative text-gray-200 bg-gray-800  px-4 py-2 text-xs font-sans justify-between rounded-t-md'>
                {language}
            </span>
            <button
              onClick={() => onCopy(String(children).trim(), children)}
              className="text-gray-200 bg-gray-800 text-xs font-sans justify-between rounded-t-md"
            >
            {copiedIndex === children ? ( 
              <span className="text-xs flex ml-auto gizmo:ml-0 gap-1 items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-sm"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.0633 5.67375C18.5196 5.98487 18.6374 6.607 18.3262 7.06331L10.8262 18.0633C10.6585 18.3093 10.3898 18.4678 10.0934 18.4956C9.79688 18.5234 9.50345 18.4176 9.29289 18.2071L4.79289 13.7071C4.40237 13.3166 4.40237 12.6834 4.79289 12.2929C5.18342 11.9023 5.81658 11.9023 6.20711 12.2929L9.85368 15.9394L16.6738 5.93664C16.9849 5.48033 17.607 5.36263 18.0633 5.67375Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Copied!
              </span>
            ) : (
              <span className="text-xs flex ml-auto gizmo:ml-0 gap-1 items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-sm"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Copy code
              </span>
            )}
            </button>
          </div>
        );
      };
      
  
  
  // Function to render code blocks
const renderCodeBlock = (text) => {
    // if (typeof text !== 'string') {
    //   console.error('Expected a string for renderCodeBlock but received:', text);
    //   return <span>Error: Non-string text received.</span>; // Or handle this case more gracefully as desired.
    // }
  
  return text.split(/(```[^\n]*?[\s\S]*?```)/).map((part, index) =>
    part.match("```[^`]*```") ? (
      <div
        key={index}
        className="bg-black rounded-md max-w-xs sm:max-w-md md:max-w-lg lg:max-w-4xl"
        style={{ position: "relative" }}
      >
        <span className="flex items-center relative text-gray-200 bg-gray-800 gizmo:dark:bg-token-surface-primary px-4 py-2 text-xs font-sans justify-between rounded-t-md">
          {part.match(/```(\w+)/)?.[1] || "code"}
          <button
            onClick={() =>
              handleCopyClick(
                part
                  .replace(/```[^\n ]*?\n?/, "")
                  .replace("```", "")  
                  .trim()  
                  .replace(/^\w+\s/, ""),  
                index,
              )
            } 
            style={{}}
            className="text-gray-200 bg-gray-800 gizmo:dark:bg-token-surface-primary text-xs font-sans justify-between rounded-t-md"
          >
            {copiedIndex === index ? ( 
              <span className="text-xs flex ml-auto gizmo:ml-0 gap-1 items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-sm"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.0633 5.67375C18.5196 5.98487 18.6374 6.607 18.3262 7.06331L10.8262 18.0633C10.6585 18.3093 10.3898 18.4678 10.0934 18.4956C9.79688 18.5234 9.50345 18.4176 9.29289 18.2071L4.79289 13.7071C4.40237 13.3166 4.40237 12.6834 4.79289 12.2929C5.18342 11.9023 5.81658 11.9023 6.20711 12.2929L9.85368 15.9394L16.6738 5.93664C16.9849 5.48033 17.607 5.36263 18.0633 5.67375Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Copied!
              </span>
            ) : (
              <span className="text-xs flex ml-auto gizmo:ml-0 gap-1 items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-sm"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z"
                    fill="currentColor"
                  ></path>
                </svg>
                Copy code
              </span>
            )}
          </button>
        </span>
        <Highlight
          className={`tp-4 overflow-y-auto text-sm language-${
            part.match(/```(\w+)/)?.[1] || "plaintext"
          }`}
        >
          {part
            .replace(/```[^\n ]*[\n ]?/, "")
            .replace(/```$/, "")
            .trim()}
        </Highlight>
      </div>
    ) : (
      <span key={index}>{renderMarkdownMessage(part)}</span>
    ),
  );
};
  
  
  return (
    <div className='flex h-full flex-col'>
    <div className="flex-1 overflow-auto">

      <div className="flex-1 overflow-y-auto bg-slate-300 text-sm leading-6 text-slate-900 dark:bg-slate-800 dark:text-slate-300 sm:text-base sm:leading-7">
      {messages.length > 0 ? (
        messages.map((message, index) => {
          let messageClass = message.sender === "You" 
            ? "flex gap-2 flex-row px-4 py-8 sm:px-6 fade-in-text"
            : "flex gap-2 bg-slate-100 px-4 py-8 dark:bg-slate-900 sm:px-6 ";

          // For sponsored messages, add additional handling if needed

          return (
            <div key={index} className={messageClass}>
              <img
                className="flex h-4 w-4 mt-[6px] items-center justify-center overflow-hidden"
                alt={message.sender}
                // src={message.sender === "You" ? "https://dummyimage.com/256x256/363536/ffffff&text=You" : "https://dummyimage.com/256x256/354ea1/ffffff&text=K"}
                src={message.sender === "You" ? "/logo192.png" : "/logoflip192.png"}

              />

              <div className="w-full	 items-center rounded-xl whitespace-break-spaces md:max-w-[80%]">
              {message.sender === "You" ? (
          // Render plain text for user messages
          <span>{message.text}</span>
        ) : (
            <span key={index}>{renderMarkdownMessage(message.text)}</span>

          // Use renderCodeBlock for bot messages
        //   renderCodeBlock(message.text)
                                 )}
                {message.sender === "You" ? " "
:              <div className="mt-4 flex flex-row gap-x-2 text-slate-500 lg:mt-0">
        <button className="hover:text-blue-600 text-xs font-sans justify-betwee" type="button"
        onClick={() => handleCopyClick(message.text, index)}
        >
            {
                          copiedIndex === index ? (
                            <span className='text-xs'>
                              <CheckIcon className="icon-md" />
                            </span>
                          ) : (
                            <span className='text-xs flex ml-auto gizmo:ml-0 gap-1 items-center'>
                                <ClipboardIcon className="icon-md" />
                            </span>
                          )
                        }
        </button>
        <div className='flex gap-1'>
        {isFeedbackVisible && (
        <FeedbackCard
          onClose={() => setIsFeedbackVisible(false)}
          feedbackType={feedbackType}
          user={user}
          conversationId={conversationId}
        />
      )}
        <button className="hover:text-blue-600"         
        onClick={() => toggleFeedback('positive')}
        >
      
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon-md"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"
            ></path>
          </svg>
        </button>
        <button className="hover:text-blue-600"  
                onClick={() => toggleFeedback('negative')}
>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon-md"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3"
            ></path>
          </svg>
        </button>
        </div> 

      </div>}
              </div>
              
            </div>  
          );
        })
        ) : (
          <div className="blank-convo-history">
         
          <h2>{animateString("KosherGPT", 0.1)}</h2>

        </div>
        )}
  

         {/* {showCopySuccess && (
            <Alert type="success" title="Copy" message="Text copied successfully!" />
            )} */}
                  <div  />
                  <div ref={messagesEndRef} />

      </div>
      {status === 'loading' && (
          <div className='flex space-x-2 justify-center items-center mt-2'>
            <span className='sr-only'>Loading...</span>
            <div className='h-2 w-2 bg-slate-600 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
            <div className='h-2 w-2 bg-slate-600 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
            <div className='h-2 w-2 bg-slate-600 rounded-full animate-bounce'></div>
          </div>
        )} 
      {overLimit && <><UpgradeButton handleTogglePricingPlans={handleTogglePricingPlans} /></> }
      </div>
    </div>
  );
};

export default MessageList;
