/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "12c42a8982204e2aa219d6c679bd3a83",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "12c42a8982204e2aa219d6c679bd3a83",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "api6182ccc0",
            "endpoint": "https://vrk5zfu4q3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yc2icmlcvjge3a2idbivrm6etq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-7xv3qja63jbkplflluqmqrqrmm",
    "aws_cognito_identity_pool_id": "us-east-1:13ba34c4-aad7-4016-9941-1348afef7432",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KoeoETBee",
    "aws_user_pools_web_client_id": "49ibp7al9hkpqer3l7503lqcat",
    "oauth": {
        "domain": "68d90e9pn5d4-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://chat.koshergpt.org/",
        "redirectSignOut": "https://chat.koshergpt.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "koshergpt4-20231221143109-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d19q5fhyde86rx.cloudfront.net",
    "aws_user_files_s3_bucket": "koshergpt4694ac462df004f3e8b759202d182d65e103218-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
