/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SelectField,
  TextField,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import {
  fetchByPath,
  getOverrideProps,
  processFile,
  validateField,
} from "./utils";
import { API } from "aws-amplify";
import { createAd } from "../graphql/mutations";
import { Field } from "@aws-amplify/ui-react/internal";
export default function AdCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    adType: "",
    company: "",
    adTitleText: "",
    adDescriptionText: "",
    adMediaUrl: undefined,
    adDestinationUrl: "",
    isActive: "",
  };
  const [adType, setAdType] = React.useState(initialValues.adType);
  const [company, setCompany] = React.useState(initialValues.company);
  const [adTitleText, setAdTitleText] = React.useState(
    initialValues.adTitleText
  );
  const [adDescriptionText, setAdDescriptionText] = React.useState(
    initialValues.adDescriptionText
  );
  const [adMediaUrl, setAdMediaUrl] = React.useState(initialValues.adMediaUrl);
  const [adDestinationUrl, setAdDestinationUrl] = React.useState(
    initialValues.adDestinationUrl
  );
  const [isActive, setIsActive] = React.useState(initialValues.isActive);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setAdType(initialValues.adType);
    setCompany(initialValues.company);
    setAdTitleText(initialValues.adTitleText);
    setAdDescriptionText(initialValues.adDescriptionText);
    setAdMediaUrl(initialValues.adMediaUrl);
    setAdDestinationUrl(initialValues.adDestinationUrl);
    setIsActive(initialValues.isActive);
    setErrors({});
  };
  const validations = {
    adType: [],
    company: [],
    adTitleText: [],
    adDescriptionText: [],
    adMediaUrl: [],
    adDestinationUrl: [],
    isActive: [{ type: "Required" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          adType,
          company,
          adTitleText,
          adDescriptionText,
          adMediaUrl,
          adDestinationUrl,
          isActive,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await API.graphql({
            query: createAd.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "AdCreateForm")}
      {...rest}
    >
      <SelectField
        label="Ad type"
        placeholder="Please select an option"
        isDisabled={false}
        value={adType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              adType: value,
              company,
              adTitleText,
              adDescriptionText,
              adMediaUrl,
              adDestinationUrl,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.adType ?? value;
          }
          if (errors.adType?.hasError) {
            runValidationTasks("adType", value);
          }
          setAdType(value);
        }}
        onBlur={() => runValidationTasks("adType", adType)}
        errorMessage={errors.adType?.errorMessage}
        hasError={errors.adType?.hasError}
        {...getOverrideProps(overrides, "adType")}
      >
        <option
          children="Text"
          value="TEXT"
          {...getOverrideProps(overrides, "adTypeoption0")}
        ></option>
        <option
          children="Banner"
          value="BANNER"
          {...getOverrideProps(overrides, "adTypeoption1")}
        ></option>
        <option
          children="Video"
          value="VIDEO"
          {...getOverrideProps(overrides, "adTypeoption2")}
        ></option>
      </SelectField>
      <TextField
        label="Company"
        descriptiveText="Client"
        isRequired={false}
        isReadOnly={false}
        value={company}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              adType,
              company: value,
              adTitleText,
              adDescriptionText,
              adMediaUrl,
              adDestinationUrl,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.company ?? value;
          }
          if (errors.company?.hasError) {
            runValidationTasks("company", value);
          }
          setCompany(value);
        }}
        onBlur={() => runValidationTasks("company", company)}
        errorMessage={errors.company?.errorMessage}
        hasError={errors.company?.hasError}
        {...getOverrideProps(overrides, "company")}
      ></TextField>
      <TextField
        label="Ad title text"
        descriptiveText="Will be Title for text ads, and will be used as ALT text for media"
        isRequired={false}
        isReadOnly={false}
        value={adTitleText}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              adType,
              company,
              adTitleText: value,
              adDescriptionText,
              adMediaUrl,
              adDestinationUrl,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.adTitleText ?? value;
          }
          if (errors.adTitleText?.hasError) {
            runValidationTasks("adTitleText", value);
          }
          setAdTitleText(value);
        }}
        onBlur={() => runValidationTasks("adTitleText", adTitleText)}
        errorMessage={errors.adTitleText?.errorMessage}
        hasError={errors.adTitleText?.hasError}
        {...getOverrideProps(overrides, "adTitleText")}
      ></TextField>
      <TextField
        label="Ad description text"
        descriptiveText="For Text Ads Only"
        isRequired={false}
        isReadOnly={false}
        value={adDescriptionText}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              adType,
              company,
              adTitleText,
              adDescriptionText: value,
              adMediaUrl,
              adDestinationUrl,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.adDescriptionText ?? value;
          }
          if (errors.adDescriptionText?.hasError) {
            runValidationTasks("adDescriptionText", value);
          }
          setAdDescriptionText(value);
        }}
        onBlur={() =>
          runValidationTasks("adDescriptionText", adDescriptionText)
        }
        errorMessage={errors.adDescriptionText?.errorMessage}
        hasError={errors.adDescriptionText?.hasError}
        {...getOverrideProps(overrides, "adDescriptionText")}
      ></TextField>
      <Field
        errorMessage={errors.adMediaUrl?.errorMessage}
        hasError={errors.adMediaUrl?.hasError}
        label={"Upload Ad Media"}
        isRequired={false}
        isReadOnly={false}
      >
        <StorageManager
          onUploadSuccess={({ key }) => {
            setAdMediaUrl((prev) => {
              let value = key;
              if (onChange) {
                const modelFields = {
                  adType,
                  company,
                  adTitleText,
                  adDescriptionText,
                  adMediaUrl: value,
                  adDestinationUrl,
                  isActive,
                };
                const result = onChange(modelFields);
                value = result?.adMediaUrl ?? value;
              }
              return value;
            });
          }}
          onFileRemove={({ key }) => {
            setAdMediaUrl((prev) => {
              let value = initialValues?.adMediaUrl;
              if (onChange) {
                const modelFields = {
                  adType,
                  company,
                  adTitleText,
                  adDescriptionText,
                  adMediaUrl: value,
                  adDestinationUrl,
                  isActive,
                };
                const result = onChange(modelFields);
                value = result?.adMediaUrl ?? value;
              }
              return value;
            });
          }}
          processFile={processFile}
          accessLevel={"public"}
          acceptedFileTypes={["video/*", "image/*"]}
          isResumable={true}
          showThumbnails={true}
          maxFileCount={1}
          {...getOverrideProps(overrides, "adMediaUrl")}
        ></StorageManager>
      </Field>
      <TextField
        label="Ad destination url"
        descriptiveText="Link to where the user should be directed"
        isRequired={false}
        isReadOnly={false}
        value={adDestinationUrl}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              adType,
              company,
              adTitleText,
              adDescriptionText,
              adMediaUrl,
              adDestinationUrl: value,
              isActive,
            };
            const result = onChange(modelFields);
            value = result?.adDestinationUrl ?? value;
          }
          if (errors.adDestinationUrl?.hasError) {
            runValidationTasks("adDestinationUrl", value);
          }
          setAdDestinationUrl(value);
        }}
        onBlur={() => runValidationTasks("adDestinationUrl", adDestinationUrl)}
        errorMessage={errors.adDestinationUrl?.errorMessage}
        hasError={errors.adDestinationUrl?.hasError}
        {...getOverrideProps(overrides, "adDestinationUrl")}
      ></TextField>
      <SelectField
        label="Is active"
        placeholder="Please select an option"
        isDisabled={false}
        value={isActive}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              adType,
              company,
              adTitleText,
              adDescriptionText,
              adMediaUrl,
              adDestinationUrl,
              isActive: value,
            };
            const result = onChange(modelFields);
            value = result?.isActive ?? value;
          }
          if (errors.isActive?.hasError) {
            runValidationTasks("isActive", value);
          }
          setIsActive(value);
        }}
        onBlur={() => runValidationTasks("isActive", isActive)}
        errorMessage={errors.isActive?.errorMessage}
        hasError={errors.isActive?.hasError}
        {...getOverrideProps(overrides, "isActive")}
      >
        <option
          children="Active"
          value="ACTIVE"
          {...getOverrideProps(overrides, "isActiveoption0")}
        ></option>
        <option
          children="Inactive"
          value="INACTIVE"
          {...getOverrideProps(overrides, "isActiveoption1")}
        ></option>
      </SelectField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
