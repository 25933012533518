import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useNavigate, useParams } from "react-router-dom";


import { ThemeContext } from "./ThemeContext";
import { Amplify, Auth,  API, graphqlOperation } from "aws-amplify";
import "./App.css";
import Conversations from "./components/Conversations";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import * as Sentry from "@sentry/react";
import { identifyKlaviyoUser, trackKlaviyoEvent } from './components/utilities/klaviyoClient';



import Sidebar from './components/SideBarV2';
import MessageList from './components/MessageListStreamV2.jsx';
import ChatInput from './components/ChatInputV2';
import Alert from './components/Alert';
import ChatHeader from './components/ChatHeader';
import awsExports from "./aws-exports";
import { localeData } from 'moment/moment';
import * as queries from './graphql/queries'; // import your GraphQL queries
import * as mutations from './graphql/mutations'; // import your GraphQL queries

Amplify.configure(awsExports);

export function ThemeProvider({ children }) {
	const [theme, setTheme] = useState("light"); // default value is 'light'

	const toggleTheme = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		// console.log("New theme:", newTheme); // add this line
		setTheme(newTheme);
	};

	React.useEffect(() => {
		// console.log("Theme changed:", theme);
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	);
}
function AppWrapper() {
    const navigation = useNavigate();
    const params = useParams(); // Retrieve the params with conversation ID
    const conversationIdFromUrl = params.conversationId; // Extract the conversation ID
    
    return <App navigate={navigation} conversationIdFromUrl={conversationIdFromUrl} />;
}

function animateString(str, delayBase) {
	let array = str.split("").map((char, i) => (
		<span
			style={{ animationDelay: `${delayBase * i}s` }}
			className="animatedChar"
		>
			{char}
		</span>
	));
	array.push(
		<img
			style={{ animationDelay: `${delayBase * str.length + 0.5}s` }}
			className="animatedCharImg"
			src="/logo192.png"
		/>
	);
	return array;
}

function animateStringNoImage(str, delayBase) {
    let array = str.split("").map((char, i) => {
        // Check if the character is not a space
        if (char !== ' ') {
            return (
                <span
                    style={{ animationDelay: `${delayBase * i}s` }}
                    className="animatedChar"
                >
                    {char}
                </span>
            );
        } else {
            // If it's a space, return a non-animated space
            return ' ';
        }
    });

    // Removed the image part

    return array;
}


class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			signOutLoading: false,
			conversationId: null,
            ws: null,
            readyState: null,
			messages: [],
			messageBuffer: '',
			status: "loading",
			error: null,
			user: null,
			idToken: null,
			group: null,
			isPlusGroup: false,
			isProGroup: false,
			isDevGroup: false,
			selectedModel: '3.5', 
			botTyping: false,
			showMenu: false,
			inputValue: "",
            copiedIndex: null,
            showCopySuccess: false,
			messageCount: 0,
            conversationHistory: [],
			conversationHistoryLoading: true,
			deleteLoading: false,
			showAlert: false,
			alertType: null,
			alertTitle: null,
			alertMessage: null,
			overLimit: false,
			isPricingPlansVisible: false,
			LocalTimezone: null,
			adsLoading: true,
			adsData: [],
			nextToken: null,
			dateOfInterest: null,
			totalConversations: null
		};
		this.hamburgerMenu = React.createRef();
	}

	// componentDidMount() {
	// 	this.initApp();
	// }
	componentDidMount() {
        this.initWebSocket();
		this.initApp();
		this.handleUrlChange();
		trackKlaviyoEvent('Page Viewed', {
			page_name: 'Chat Page',
			url: window.location.href,
			timestamp: new Date().toISOString(),
		});
	}
	
    componentWillUnmount() {
        if (this.state.ws) {
            this.state.ws.close();
        }
    }
    
    initWebSocket() {
        // console.log("Initializing WebSocket connection...");

        // const websocket = new WebSocket("wss://1y9f580a62.execute-api.us-east-1.amazonaws.com/production/");
		const websocketURL = `wss://api.koshergpt.org/?token=${encodeURIComponent(this.state.idToken)}`;

		// const websocket = new WebSocket("wss://api.koshergpt.org/");
		const websocket = new WebSocket(websocketURL);

        websocket.onopen = () => {
            // console.log("WebSocket connection opened.");
			this.setState({ readyState: websocket.readyState });

			// Only change status to 'idle' if username is not null
			if (this.state.user) {
				this.setState({ status: 'idle',  botTyping: false });
			}
			
        };
        websocket.onerror = (errorEvent) => {
            // console.error("WebSocket error:", errorEvent.message);
            this.setState({ error: `WebSocket error: ${errorEvent.message}` });
        };
        websocket.onmessage = (event) => {
            // console.log("WebSocket message received:", event.data);
            const data = JSON.parse(event.data);

        
            if (data.error) {
                // Handle the error case
                // console.error("WebSocket data error:", data.error);
                this.setState({ 
                    error: data.error,
                    status: "error",
                    botTyping: false
                });
            } else if (data.complete) {
				// console.log('Complete message:', this.state.messageBuffer);
				this.createMessage('ASSISTANT', this.state.messageBuffer, this.state.conversationId);
				// Track the event of sending a message
				trackKlaviyoEvent('Message Recevied', {
					message: this.state.messageBuffer,
					conversation_id: this.state.conversationId,
				});
				this.setState({ 
					messageBuffer: '', 
					botTyping: false
				});
			} else if (data.overLimit) {
				// console.log('Complete message:', this.state.messageBuffer);
				this.createMessage('ASSISTANT', this.state.messageBuffer, this.state.conversationId);
				trackKlaviyoEvent('Message Limit Reached', {
					message: this.state.messageBuffer,
					conversation_id: this.state.conversationId
				});

				this.setState({ 
					messageBuffer: '', 
					botTyping: false,
					overLimit: true
				});
			} else {
				this.state.messageBuffer += data.message;

                // Handle the successful message reception
                this.setState((prevState) => {
                    const prevMessages = prevState.messages;
                    const lastMessage = prevMessages[prevMessages.length - 1];
        
                    let newMessages;
                    if (lastMessage && lastMessage.sender === "KosherGPT") {
                        // Concatenate the new message part to the last message if it's from the bot
                        newMessages = prevMessages.slice(0, -1).concat([{
                            sender: lastMessage.sender,
                            text: lastMessage.text + data.message,
                        }]);
                    } else {
                        // Add a new bot message
                        newMessages = [...prevMessages, { sender: "KosherGPT", text: data.message }];
                    }
                    return {
                        messages: newMessages,
                        status: "success",
                        // botTyping: false,
                    };

                });
            }
        };
        
        websocket.onclose = (event) => {
            // console.log("WebSocket connection closed:", event.reason);
			this.setState({ readyState: websocket.readyState, ws: null });
        };
    
        this.setState({ ws: websocket });
    }
    
	restartWebSocket = () => {
		const { ws } = this.state;
		// console.log('Restarting WebSocket...');
	
		const restartAfterClose = () => {
			// console.log('Existing WebSocket connection closed. Initializing new connection...');
			this.initWebSocket();
			// console.log('WebSocket connection initialized.');
		};
	
		if (ws && ws.readyState === WebSocket.OPEN) {
			// console.log('Closing existing WebSocket connection.');
			ws.onclose = restartAfterClose; // Set the onclose event handler
			ws.close();
		} else {
			// console.log('No active WebSocket connection to close. Initializing new connection...');
			this.initWebSocket();
			// console.log('WebSocket connection initialized.');
		}
	};
	
    

    sendMessage = () => {
        const { ws, newMessage } = this.state;
        if (ws && newMessage.trim()) {
            ws.send(JSON.stringify({ message: newMessage }));
			this.setState({
				botTyping: true, 
			});
        }
    };

	handleDeleteChat = (conversationId) => {
		this.setState({ deleteLoading: true }, () => {
			// Now this code runs after deleteLoading is set to true
			// console.log(`Initiating delete for conversationId: ${conversationId}. deleteLoading status: ${this.state.deleteLoading}`);
	
			try {
				const { ws } = this.state;
	
				const deleteMessage = JSON.stringify({
					function_type: "delete",
					conversation_id: conversationId,
				});
	
				// console.log(`Sending delete request via WebSocket: ${deleteMessage}`);
				ws.send(deleteMessage);
	
				ws.onmessage = (event) => {
					const response = JSON.parse(event.data);
					// console.log("Deletion response received:", response);
	
					if(response.status === "success") {
						// console.log(`Deletion successful for conversationId: ${conversationId}`);
					}
					// Set deleteLoading to false after receiving the response
					this.setState({ deleteLoading: false });
					this.fetchConversationHistory(this.state.user.username, this.state.group);
				};
	
			} catch (error) {
				// console.error('Error sending delete request via WebSocket:', error);
				this.setState({ deleteLoading: false }); // Ensure loading is false after an error
				this.fetchConversationHistory(this.state.user.username, this.state.group);
			}
		});
	};
	

	renameChat = (conversationId, conversationSubject) => {
		// Now this code runs after deleteLoading is set to true
		console.log(`Initiating subject update`);
		this.setState({ conversationHistoryLoading: true });
		try {
			const { ws } = this.state;
			const updateSubject = JSON.stringify({
				function_type: "update_subject",
				conversation_id: conversationId,
				subject_name: conversationSubject,
			});

			ws.send(updateSubject);

			ws.onmessage = (event) => {
				const response = JSON.parse(event.data);
				console.log("Update subject response received:", response);

				if(response.status === "success") {
					console.log(`Update successful for conversationId: ${conversationId}`);
				}
				this.fetchConversationHistory(this.state.user.username, this.state.group);
			};

		} catch (error) {
			console.error('Error sending update request:', error);
			this.fetchConversationHistory(this.state.user.username, this.state.group);
		}

	};
	
	

	async initApp() {
		// console.log('initApp called');
		try {
			const user = await Auth.currentAuthenticatedUser();
			console.log('user:', user);
			
	
			// Fetch real-time group information from your backend
			const realTimeGroupInfo = await this.fetchRealTimeGroupInfo(user.username,user.pool.userPoolId);

			// console.log('Real-time User groups:', realTimeGroupInfo);
	
			// Prioritize real-time group info; fallback to decoded ID token if unavailable
			let groups = realTimeGroupInfo 
						   ? realTimeGroupInfo 
						   : this.decodeIdTokenGroups(user.signInUserSession.idToken.jwtToken);

			console.log('Groups:', groups);


			// If no groups are available, default to 'free'
			if (!groups || groups.length === 0) {
				groups = ['free'];
				console.log('Defaulting to free group as no groups are available');
			}						   
			// Prioritize group assignment: pro > plus > dev > free
			let group;
			if (groups.includes('admin')) {
				group = 'admin';
			} else if (groups.includes('filterOrg')) {
				group = 'filterOrg';
			} else if (groups.includes('pro')) {
				group = 'pro';
			} else if (groups.includes('plus')) {
				group = 'plus';
			} else if (groups.includes('dev')) {
				group = 'dev';
			} else {
				group = 'free';
			}
	
			console.log('Group to use:', group);
	
			// Retrieve the Stripe customer ID from user attributes
			const stripeCustomerId = user.attributes['custom:STRIPE_CUSTOMER_ID'];
			// console.log('Stripe Customer ID:', stripeCustomerId);
			const idToken = user.signInUserSession.idToken;
	
			// Determine group membership
			const isFilterGroup = groups.includes('filterOrg');
			const isProGroup = groups.includes('pro');
			const isPlusGroup = groups.includes('plus');
			const isDevGroup = groups.includes('dev');
	
			// const conversationId = "chat-" + Math.random().toString(36).substring(7);
			const LocalTimezone = moment.tz.guess();

			Sentry.setUser({
				email: user.attributes.email
			  });
			// Update state with user, conversationId, group membership status, Stripe customer ID
			// this.setState({ user, conversationId, isPlusGroup, stripeCustomerId, isProGroup, group });
			this.setState({ user, isPlusGroup, isDevGroup, stripeCustomerId, isProGroup, group , idToken, LocalTimezone});

			// Change status to 'idle' if WebSocket is connected
			if (this.state.ws && this.state.ws.readyState === WebSocket.OPEN) {
				this.setState({ status: 'idle' });
			}
			
			identifyKlaviyoUser(user, {
				// signup_method: 'Cognito',
				// signup_date: new Date().toISOString(),
				groups: groups
			});


			// Fetch the conversation history here using the first group
			this.fetchConversationHistory(user.username, group);
			
	
		} catch (err) {
			console.error('Error initializing:', err);
		}
	}
	

	decodeIdTokenGroups(idToken) {
		const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
		return decodedToken['cognito:groups'] || [];
	}
	
	fetchRealTimeGroupInfo = async (username, userPoolId) => {
		const lambdaEndpoint = 'https://lstgbqwwbf.execute-api.us-east-1.amazonaws.com/default/kosherGPT-connect-stripe-amplify'; // Replace with your Lambda endpoint
	
		try {
			const response = await fetch(lambdaEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					type: 'check-user-group',
					username: username,
					userPoolId: userPoolId
				})
			});
	
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
	
			const data = await response.json();
			// console.log('User groups:', data.groups);
			return data.groups;
		} catch (error) {
			// console.error('Error fetching user group:', error);
			return null;
		}
	}

	componentDidUpdate(prevProps) {
		// Scroll chat history to the bottom whenever state updates
		const chatHistory = this.chatHistory;
		if (chatHistory) {
			setTimeout(() => {
				chatHistory.scrollTop = chatHistory.scrollHeight;
			}, 0); // delay of 0ms
		}
	
		// Check if the conversation ID from URL has changed
		// and ignore changes from null/undefined to an ID (initial load)
		const prevConversationId = prevProps.conversationIdFromUrl;
		const currentConversationId = this.props.conversationIdFromUrl;
	
		// if (prevConversationId && currentConversationId !== prevConversationId) {
		// 	this.handleUrlChange();
		// }       
	}
	
	
	handleUrlChange = () => {
		const { conversationIdFromUrl } = this.props;
		if (conversationIdFromUrl && conversationIdFromUrl !== this.state.conversationId) {
			// Fetch messages for the new conversation ID
			this.fetchConversationMessages(conversationIdFromUrl);
			// Update the local state with the new conversation ID
			this.setState({ conversationId: conversationIdFromUrl });
		}
		// Additional logic for URL change
	};
	

	handleInputChange = (event) => {
		this.setState({ inputValue: event.target.value });
	};

	handleCloseMenu = () => {
		this.setState({ showMenu: false });
	};

	handleToggleMenu = async () => {
		this.setState({ showMenu: !this.state.showMenu });
	};
	

	// fetchConversationHistory = async (username, group = "free") => {
	// 	this.setState({ conversationHistoryLoading: true, adsLoading: true });
	
	// 	let limit = group === "free" ? 10 : undefined;
	
	// 	try {
	// 		const [conversationData, adsData] = await Promise.all([
	// 			API.graphql(
	// 				graphqlOperation(queries.conversationsByUserId, {
	// 					userId: username,
	// 					sortDirection: 'DESC',
	// 					limit: limit
	// 				})
	// 			),
	// 			this.fetchAds(group)
	// 		]);
	
	// 		console.log("Fetched Conversations:", conversationData);
	// 		console.log("Fetched Ads:", adsData);
	
	// 		// Check if both conversation history and ads are available
	// 		if (conversationData.data.conversationsByUserId.items.length && adsData && adsData.data.adsByIsActiveAndUpdatedAt.items.length) {
	// 			let mergedData = this.mergeAdsAndConversations(conversationData.data.conversationsByUserId.items, adsData.data.adsByIsActiveAndUpdatedAt.items);
	// 			this.setState({ conversationHistory: mergedData });
	// 		} else {
	// 			// Handle the case where either or both are missing
	// 			this.setState({ conversationHistory: conversationData.data.conversationsByUserId.items });
	// 		}
	// 	} catch (error) {
	// 		console.error("Error fetching data:", error);
	// 	}
	
	// 	this.setState({ conversationHistoryLoading: false, adsLoading: false });
	// };
	fetchConversationHistory = async (username, group = "free", dateOfInterest = null, nextToken = null) => {
		this.setState({ conversationHistoryLoading: true });
	
		let limit = group === "free" ? 10 : undefined;
	
		try {
			let conversationData;
	
			// Check if the user is an admin or not and call the appropriate API
			if (group === "admin") {
			
const limit = 250; // Change limit to 250 for pagination
const allConversations = [];
let nextToken = null;

// Helper function to format date in 'YYYY-MM-DD' format considering local timezone
const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

// Set the date of interest if not provided
if (!dateOfInterest) {
  dateOfInterest = formatDate(new Date()); // Formats date as 'YYYY-MM-DD'
}

// Calculate start and end dates based on dateOfInterest
const startDate = moment(dateOfInterest).startOf('day');
const endDate = moment(startDate).add(1, 'days');

// Format start and end dates for the query
const formattedStartDate = formatDate(startDate);
const formattedEndDate = formatDate(endDate);

// Log the filters being used
console.log(`Filters: Start Date = ${formattedStartDate}, End Date = ${formattedEndDate}`);

do {
  const conversationData = await API.graphql(
    graphqlOperation(queries.searchConversations, {
      filter: {
        and: [
          { createdAt: { lt: formattedEndDate } },
          { createdAt: { gte: formattedStartDate } }
        ]
      },
      sort: {
        direction: 'desc',
        field: 'createdAt'
      },
      limit: limit,
      nextToken: nextToken
    })
  );

  const sortedConversations = conversationData.data.searchConversations.items;
  allConversations.push(...sortedConversations);
  nextToken = conversationData.data.searchConversations.nextToken;

  // Log results of current batch
  console.log(`Fetched ${sortedConversations.length} conversations in this batch.`);

} while (nextToken);

// Log total number of conversations fetched
console.log(`Total conversations fetched: ${allConversations.length}`);

this.setState({
  conversationHistory: allConversations,
  totalConversations: allConversations.length
});


			}else if (group === "filterOrg") {


const limit = 250; // Change limit to 250 for pagination
const allConversations = [];
let nextToken = null;

// Helper function to format date in 'YYYY-MM-DD' format considering local timezone
const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

// Set the date of interest if not provided
if (!dateOfInterest) {
  dateOfInterest = formatDate(new Date()); // Formats date as 'YYYY-MM-DD'
}

// Calculate start and end dates based on dateOfInterest
const startDate = moment(dateOfInterest).startOf('day');
const endDate = moment(startDate).add(1, 'days');

// Format start and end dates for the query
const formattedStartDate = formatDate(startDate);
const formattedEndDate = formatDate(endDate);

// Log the filters being used
console.log(`Filters: Start Date = ${formattedStartDate}, End Date = ${formattedEndDate}`);

do {
  const conversationData = await API.graphql(
    graphqlOperation(queries.searchConversations, {
      filter: {
        and: [
          { createdAt: { lt: formattedEndDate } },
          { createdAt: { gte: formattedStartDate } }
        ]
      },
      sort: {
        direction: 'desc',
        field: 'createdAt'
      },
      limit: limit,
      nextToken: nextToken
    })
  );

  const sortedConversations = conversationData.data.searchConversations.items;
  allConversations.push(...sortedConversations);
  nextToken = conversationData.data.searchConversations.nextToken;

  // Log results of current batch
  console.log(`Fetched ${sortedConversations.length} conversations in this batch.`);

} while (nextToken);

// Log total number of conversations fetched
console.log(`Total conversations fetched: ${allConversations.length}`);

this.setState({
  conversationHistory: allConversations,
  totalConversations: allConversations.length
});



			} else {
				// For regular users, fetch both conversations and ads
				const [fetchedConversationData, adsData] = await Promise.all([
					API.graphql(
						graphqlOperation(queries.conversationsByUserId, {
							userId: username,
							sortDirection: 'DESC',
							limit: limit,
							nextToken: nextToken
						})
					),
					this.fetchAds(group)
				]);
				conversationData = fetchedConversationData;
	
				// Handle merging with ads data if needed
				if (adsData && adsData.data.adsByIsActiveAndUpdatedAt.items.length) {
					let mergedData = this.mergeAdsAndConversations(conversationData.data.conversationsByUserId.items, adsData.data.adsByIsActiveAndUpdatedAt.items);
					this.setState({ conversationHistory: mergedData });
				} else {
					// Update the state with the fetched conversation data and the next token for regular users
					this.setState({
						conversationHistory: conversationData.data.conversationsByUserId.items,
						nextToken: conversationData.data.conversationsByUserId.nextToken
					});
				}
			}
	
			console.log("Fetched Conversations:", conversationData);
	
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	
		this.setState({ conversationHistoryLoading: false });
	};
	
	
	fetchAds = async (group) => {
		let limit = group === "free" ? 10 : undefined;
		try {
			const adsData = await API.graphql(
				graphqlOperation(queries.adsByIsActiveAndUpdatedAt, {
					isActive: 'ACTIVE',
					// isActive: 'INACTIVE',
					sortDirection: 'DESC',
					limit: limit
				})
			);
			console.log("Fetched ads:", adsData);
			return adsData;
		} catch (error) {
			console.error("Error fetching ads:", error);
		}
	};
	
	mergeAdsAndConversations = (conversations, ads) => {
		let merged = [];
		for (let i = 0; i < conversations.length; i++) {
			merged.push(conversations[i]);
			if (ads[i]) merged.push(ads[i]); // Only add an ad if one is available
		}
		return merged;
	};
	

	
	fetchConversationMessages = async (conversationID) => {
		try {
			// Use the messagesByDate query with sortDirection set to ascending
			const messagesData = await API.graphql(
				graphqlOperation(queries.messagesByDate, {
					conversationID: conversationID,
					sortDirection: 'ASC'
				})
			);
			console.log('messagesData:', messagesData);
	
			if (!messagesData.data.messagesByDate.items.length) {
				// If no messages, clear conversation ID and update URL
				this.setState({
					conversationId: null,
					showAlert: true,
					alertType: 'error',
					alertTitle: 'Error',
					alertMessage: `Conversation ID ${conversationID} not found.`
				}, () => {
					this.props.navigate('/chat'); // Assuming 'navigate' is correctly provided via props
				});
				return;
			}
	
			// Process and format messages for the state
			const formattedMessages = messagesData.data.messagesByDate.items.map((message) => ({
				text: message.value,
				sender: message.type === "USER" ? "You" : "KosherGPT"
			}));
	
			this.setState({ messages: formattedMessages });
			// Track the event of viewing a conversation

			trackKlaviyoEvent('Conversation Viewed', {
				conversation_id: conversationID,
				message_count: formattedMessages.length
			});			
		} catch (error) {
			console.error("Error fetching conversation messages:", error);
			// Show error alert for any other errors
			this.setState({
				conversationId: null,
				showAlert: true,
				alertType: 'error',
				alertTitle: 'Error',
				alertMessage: error.message || "An error occurred while fetching messages."
			});
		}
	};

	handleReconnectChat = (conversationId) => {
		this.setState({ conversationId }, async () => {
			await this.fetchConversationMessages(conversationId);
			this.setState({ showMenu: false });
			// Update the URL
			this.props.navigate(`/chat/${conversationId}`);
			this.restartWebSocket();
		});
	};
	


	async createConversation() {
		try {
			const newConversation = { subject: "New chat", userId: this.state.user.username };
			const conversationData = await API.graphql(graphqlOperation(mutations.createConversation, { input: newConversation, reviewed: 'false' }));
			const conversationId = conversationData.data.createConversation.id;
	        this.props.navigate(`/chat/${conversationId}`);
			this.setState({ conversationId: conversationId });
			console.log('New conversation created:', conversationId);
			return conversationId;
		} catch (error) {
			console.error('Error creating conversation:', error);
			return null;
		}
	}

	async createMessage(type, value, conversationID, sScore, sCategory, cost) {
		try {
			// Construct a new message object based on the provided parameters
			const newMessage = {
				type: type,
				value: value,
				conversationID: conversationID,
				sexualScore: sScore,
				sexualCategory: sCategory,
				cost: cost
			};
	
			// Use GraphQL mutation to create the new message
			const messageData = await API.graphql(
				graphqlOperation(mutations.createMessage, { input: newMessage })
			);
	
			// Extract the message ID from the response
			const messageId = messageData.data.createMessage.id;
	
			// console.log('New message created:', messageId);
			return messageId;
		} catch (error) {
			// console.error('Error creating message:', error);
			return null;
		}
	}

    // handleInput = async (inputValue, isPrompt = null) => {
    //     if (!inputValue) return;
    
    //     this.setState(prevState => ({
    //         messages: [...prevState.messages, { text: inputValue, sender: "You" }],
    //         inputValue: "",
    //         messageCount: prevState.messageCount + 1,
    //         status: "loading",
    //         error: null,
    //         botTyping: true,
    //     }));
    
    //     let conversationId = this.state.conversationId;
    //     let isNew = false;
    
    //     if (!conversationId) {
    //         conversationId = await this.createConversation();
    //         isNew = true;
    //         if (!conversationId) {
    //             return;
    //         }
    //     }
    //     const requestData = {
    //         function_type: "generateResponse",
	// 		local_timezone: this.state.LocalTimezone,
	// 		owner: this.state.user.username,
    //         conversation_id: conversationId,
    //         message: inputValue,
    //         messageCount: this.state.messageCount,
    //         group: this.state.group,
    //         is_plus_group: this.state.isPlusGroup,
    //         is_new: isNew,
    //         is_prompt: isPrompt
    //     };
    
    //     if (this.state.ws) {
    //         this.state.ws.send(JSON.stringify(requestData));
    //     }
	// 	await this.createMessage('USER', inputValue, conversationId);
    // };

	handleInput = async (inputValue, isPrompt = null) => {
		if (!inputValue) return;
	
		let conversationId = this.state.conversationId;
		let isNew = false;
	
		// First, ensure there's a conversationId and WebSocket connection
		if (!conversationId) {
			conversationId = await this.createConversation();
			isNew = true;
			if (!conversationId) {
				this.setState({
					status: 'error',
					error: 'Failed to create a new conversation. Please try reloading the page.',
				});
				return;
			}
		}
	
		if (!this.state.ws || this.state.ws.readyState !== WebSocket.OPEN) {
			this.setState({
				status: 'error',
				error: 'Connection is not active. Please reload the page to reconnect.',
			});
			return;
		}
	
		// All checks passed, proceed with setting loading state and handling the input
		this.setState(prevState => ({
			messages: [...prevState.messages, { text: inputValue, sender: "You" }],
			inputValue: "",
			messageCount: prevState.messageCount + 1,
			status: "loading",
			error: null,
			botTyping: true,
		}));
	
		const requestData = {
			function_type: "generateResponse",
			local_timezone: this.state.LocalTimezone,
			owner: this.state.user.username,
			conversation_id: conversationId,
			message: inputValue,
			messageCount: this.state.messageCount,
			group: this.state.group,
			is_plus_group: this.state.isPlusGroup,
			is_new: isNew,
			is_prompt: isPrompt
		};
	
		this.state.ws.send(JSON.stringify(requestData));
		await this.createMessage('USER', inputValue, conversationId);
		// Track the event of sending a message
		trackKlaviyoEvent('Message Sent', {
			message: inputValue,
			conversation_id: conversationId,
			timestamp: new Date().toISOString(),
			is_new_conversation: isNew,
			is_prompt: isPrompt
		});

	};
	

    

	handleModelChange = (model) => {
		this.setState({ selectedModel: model });
	};

	  
	handleNewConversation = () => {
		this.setState(
			{ conversationId: null, messages: [], status: "idle", error: null },
			async () => {
				this.props.navigate("/chat");
				try {
					await this.fetchConversationHistory(this.state.user.username, this.state.group);
				} catch (error) {
					// console.error(
					// 	"Error refetching conversation history:",
					// 	error.message
					// );
				}
			}
		);
	};

	handleSignOut = async () => {
		try {
			this.setState({ signOutLoading: true }); // Start loading
			// console.log("started loading", this.state.signOutLoading);

			setTimeout(async () => {
				try {
					await Auth.signOut();
					// console.log("stopped loading", this.state.signOutLoading);
					this.setState({ signOutLoading: false }); // Stop loading
					this.props.navigate("/");
					// console.log("Signing out...");
				} catch (error) {
					// console.log("Error signing out:", error.message);
				}
			}, 1000); // adjust the delay time according to your needs
		} catch (error) {
			// console.log(error);
		}
	};

	handleKeyPress = (event) => {
		// Check if the Enter key is pressed without the Shift key
		if (event.key === "Enter" && !event.shiftKey) {
			// Prevent the default action to avoid a new line in textarea
			event.preventDefault();
	
			// Check if the input is not empty and the status is not loading
			if (this.state.inputValue.trim() && this.state.status !== "loading") {
				// Call handleInput with the current inputValue
				this.handleInput(this.state.inputValue);
			}
		}
	};
	

	// Add a function to handle copying text to the clipboard
	handleCopyClick = (text, index) => {
        navigator.clipboard.writeText(text).then(
          () => {
            this.setState({ copiedIndex: index, showCopySuccess: true });
            setTimeout(() => {
              this.setState({ copiedIndex: null, showCopySuccess: false });
            }, 5000);
          },
          (err) => {
            // console.error("Copy failed: ", err);
          }
        );
      };

	  handleTogglePricingPlans = () => {
		this.setState(prevState => ({
		  isPricingPlansVisible: !prevState.isPricingPlansVisible
		}));
	  };

	render() {
		if (this.state.signOutLoading) {
			return (
				<div className="redirect-spinner-cont">
					<div className="redirect-spinner">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			);
		}
		return (
			<ThemeContext.Consumer>
				{({ theme, toggleTheme }) => {
					return (
						<div className='h-full h-screen	'>
							<div className={this.state.showMenu ? "overlay" : ""}></div>

							<div
								className="relative z-0 flex h-full w-full overflow-hidden	bg-slate-300 dark:bg-slate-700 "
							>
								
                                
								{/* <header
									className="mobile"
									style={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<button
										onClick={this.handleToggleMenu}
										className="header-icons mobile toggleMenu"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											fontSize: "20px",
											lineHeight: "20px",
											// background: theme === "dark" ? "#333" : "#f0f0f0",
											// color: theme === "dark" ? "#f0f0f0" : "#333",
											// marginLeft: "5px",
											zIndex: "1",
											position: "relative",
										}}
									>
										<svg
											className="top-icon-svg"
											stroke="currentColor"
											fill="none"
											strokeWidth="1.5"
											viewBox="0 0 24 24"
											strokeLinecap="round"
											strokeLinejoin="round"
											xmlns="http://www.w3.org/2000/svg"
										>
											<line x1="3" y1="12" x2="21" y2="12"></line>
											<line x1="3" y1="6" x2="21" y2="6"></line>
											<line x1="3" y1="18" x2="21" y2="18"></line>
										</svg>
									</button>
									 <h1>
										{this.state.user &&
											`Hi, ${this.state.user.attributes.email}`}
									</h1> 
								</header> */}
								

                                <Sidebar
                                        showMenu={this.state.showMenu}
                                        user={this.state.user && this.state.user}
										group={this.state.group}
										isPlusGroup = {this.state.user && this.state.isPlusGroup}
										isProGroup = {this.state.user && this.state.isProGroup}
                                        conversationHistory={this.state.conversationHistory}
                                        handleReconnectChat={this.handleReconnectChat}
                                        handleNewConversation={this.handleNewConversation}
										handleDeleteChat={this.handleDeleteChat}
										deleteLoading={this.state.deleteLoading}
										renameChat={this.renameChat}
										fetchConversationHistory={this.fetchConversationHistory}
										conversationHistoryLoading={this.state.conversationHistoryLoading}
                                        handleCloseMenu={this.handleCloseMenu}
                                        handleToggleMenu={this.handleToggleMenu}
                                        status={this.state.status}
                                        handleSignOut={this.handleSignOut}
                                        toggleTheme={toggleTheme} 
                                        theme={theme}
										isPricingPlansVisible={this.state.isPricingPlansVisible}
										handleTogglePricingPlans={this.handleTogglePricingPlans}
										totalConversations={this.state.totalConversations}
                                    />


									<div className={` ${this.state.messageCount === 0 ? "" : "relative flex h-full max-w-full flex-1 flex-col overflow-hidden" } w-full dark:bg-slate-700 dark:text-slate-300`}>
										<div
											ref={this.hamburgerMenu}
											className={`hamburger-menu ${
												this.state.showMenu
													? theme === "dark"
														? "dark-visible"
														: "light-visible"
													: theme === "dark"
													? "dark-hidden"
													: "light-hidden"
											}`}
										>
											<button
												type="button"
												className="close-icon"
												tabIndex="0"
												onClick={() => {
													this.handleCloseMenu();
												}}
												style={{
													display: this.state.showMenu ? "block" : "none",
												}}
											>
												<span className="sr-only">Close sidebar</span>
												<svg
													className="top-icon-svg"
													stroke="currentColor"
													fill="none"
													strokeWidth="1.5"
													viewBox="0 0 24 24"
													strokeLinecap="round"
													strokeLinejoin="round"
													xmlns="http://www.w3.org/2000/svg"
												>
													<line x1="18" y1="6" x2="6" y2="18"></line>
													<line x1="6" y1="6" x2="18" y2="18"></line>
												</svg>
											</button>
										
										</div>
										
{/* 										
										<header className="flex justify-between	w-full items-center md:hidden bg-slate-200 p-3 text-sm text-slate-900 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-slate-800 dark:text-slate-200 dark:placeholder-slate-400 dark:focus:ring-blue-600 sm:text-base">
											<h1 className="text-center 	m-auto text-slate-700 dark:text-slate-200">
												{this.state.user && `Hi, ${this.state.user.attributes.email}`}
											</h1> 
										</header> */}
										<div  className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
										{this.state.showAlert && <Alert type={this.state.alertType} title={this.state.alertTitle} message={this.state.alertMessage} />}

											<div

                                                className="relative h-full w-full flex-1 overflow-auto transition-width"
												ref={(el) => {
													this.chatHistory = el;
												}}
												style={
													this.state.messages.length === 0
														? { margin: "auto", opacity: 0.5 }
														: {}
												}
											>
                                                     
                                                    {this.state.messages.length === 0 ? (
														<>
														
                                                    <div className='relative h-full'>
													<div className="flex h-full flex-col items-center justify-center">
														{/* <ChatHeader onModelSelect={this.handleModelChange} isPlusGroup={this.state.isPlusGroup} /> */}
														<h2 className='mb-5 text-4xl font-medium'>KosherGPT</h2>
                                                        <h3 className='mb-5 text-2xl font-medium'>{animateStringNoImage("How can I help you today?", 0.05)}</h3>
														</div>
													</div>
													
													</>
												) : (
													<React.Fragment>
														<MessageList 
															user={this.state.user}
															conversationId={this.state.conversationId}
                                                            messages={this.state.messages} 
                                                            theme={theme}
                                                            handleCopyClick={this.handleCopyClick.bind(this)}
                                                            copiedIndex={this.state.copiedIndex}
                                                            showCopySuccess={this.state.showCopySuccess}
                                                            handleToggleMenu={this.handleToggleMenu}
                                                            status={this.state.status}
															overLimit={this.state.overLimit}
															isPricingPlansVisible={this.state.isPricingPlansVisible}
															handleTogglePricingPlans={this.handleTogglePricingPlans}
                                                        />

														{this.state.status === "error" && (
															<div className="m-cont-gpt-response error">
																Error: {this.state.error}. Please try again.
															</div>
														)}
													</React.Fragment>
												)}
											</div>
											<div className="w-full pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]">

                                                <ChatInput 
													group={this.state.group}
                                                    inputValue={this.state.inputValue}
                                                    onInputChange={this.handleInputChange}
                                                    onKeyPress={this.handleKeyPress}
                                                    handleInput={this.handleInput}
													restartWebSocket={this.restartWebSocket}
													botTyping={this.state.botTyping}
                                                    status={this.state.status}
													messagesLength={this.state.messages.length}
                                                />
												
                                                {/* <div className="footer "> */}
                                                    <div className="relative px-2 py-2 text-center text-xs text-gray-600 dark:text-gray-300 md:px-[60px]">
                                                        <p className="text-xs pt-1 text-center	 text-gray-600 dark:text-slate-300">
                                                        ©KosherGPT {(new Date().getFullYear())}.
                                                        </p>
                                                    </div>
                                                {/* </div> */}


										</div>
									</div>
                                </div>
							</div>
						</div>
					);
				}}
			</ThemeContext.Consumer>
		);
	}
}

export default withAuthenticator(AppWrapper);
