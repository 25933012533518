import React, { useEffect, useRef } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';

const UserCard = ({ isPlusGroup, isProGroup, user, group, onClose, setIsPricingPlansVisible, togglePricingPlans, loading, setLoading }) => {
  const cardRef = useRef();

    // Determine the button text and plan status based on the group
    let buttonText, planStatus;

    if (isProGroup) {
      buttonText = '✨ Upgrade to Plus';
      planStatus = 'Pro';
    } else if (isPlusGroup) {
      buttonText = 'Manage Plus Plan';
      planStatus = 'Plus';
    } else {

      if (group === 'new') {
        buttonText = 'Get Started';
        planStatus = 'New';
        } else {
          buttonText = '✨ Upgrade to Plus';
          planStatus = 'Free';
        }

    }
    

  const planStatusClasses = `rounded-full px-2.5 py-1 text-xs font-semibold leading-5 ${isPlusGroup ? 'bg-blue-600/10 text-blue-600' : 'bg-green-600/10 text-green-600'}`;



// Function to handle outside click
const handleClickOutside = (event) => {
  if (cardRef.current && !cardRef.current.contains(event.target)) {
    onClose();
  }
};

// Attach event listener on mount and remove on unmount
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [onClose]); // Ensure onClose dependency is specified
  
  return (
    <div className="fixed m-0	my-0	mt-0	  inset-0 z-50 flex items-center justify-center bg-black/30"> {/* Overlay with light gradient */}
    <div ref={cardRef}  className="w-96	rounded-lg border border-slate-200 bg-white px-3 py-6 shadow dark:border-slate-700 dark:bg-slate-900">
      <div className='max-w-sm'>
      <div className="flex items-center justify-between">
        <div className="flex">
          <div className="relative inline-flex">
            {/* <span
              className="absolute bottom-0 right-0 h-3 w-3 rounded-full border bg-green-600 dark:border-slate-900 dark:bg-green-600 dark:text-slate-100"
            ></span> */}
              {user && user.attributes.picture ? (
                <img src={user.attributes.picture} className='h-6 w-6' alt="Profile" />
              ) : (
                <UserCircleIcon className='h-6 w-6' strokeWidth="2" />
              )}          </div>
          <div className="ml-4 flex flex-col gap-y-2">
            <h3 className="text-sm font-bold text-slate-900 dark:text-slate-200">
            {user && user.attributes.given_name ? (
              // Display given_name and family_name if available
              `${user.attributes.given_name} ${user.attributes.family_name ? user.attributes.family_name : ''}`
            ) : (
              // If no given_name, display the first letter of the email
              user && user.attributes.email ? user.attributes.email.charAt(0).toUpperCase() : ''
            )}
            </h3>
            <span className="text-xs text-slate-400">{user && user.attributes.email}</span>
          </div>
        </div>
        <span className={planStatusClasses}>
          {planStatus}
        </span>
      </div>
      <button
                className="mt-6 w-full rounded-lg border border-slate-300 p-4 text-center text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-blue-600 hover:text-slate-50 focus:outline-none dark:border-slate-700 dark:text-slate-200"
                type="button"
                onClick={(event) => {
                  event.stopPropagation(); // Stop event from bubbling
                  setIsPricingPlansVisible(prevState => !prevState); // Toggle the state based on the previous state
                }}
                // disabled={loading}
            >
                {loading ? 'Loading...' : buttonText}
            </button>
          </div>
    </div>


    </div>
  );
};

export default UserCard;
