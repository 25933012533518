import React from 'react';
import { ArrowRightIcon, CodeBracketIcon, LightBulbIcon, ArrowTrendingUpIcon, CursorArrowRaysIcon } from '@heroicons/react/24/solid';

const IndividualPromptComponent = ({ title, category, Icon, size, handleInput, prompt }) => {
  return (
    <div 
      className="cursor-pointer rounded-lg border-[0.1rem] border-slate-300 bg-slate-50 transition-colors hover:border-blue-600 dark:border-slate-300/20 dark:bg-slate-900 dark:hover:border-blue-600"
      onClick={() => handleInput(prompt, category)}
    >
      <div className="flex w-full items-center gap-2 px-2 py-2 justify-center">
        <Icon className={`h-${size} w-4 text-slate-700 dark:text-slate-200`} />
        <div className="flex items-center justify-start gap-2 text-slate-700 dark:text-slate-200">
          <h3 className="inline-flex lg:text-base tracking-wide">{title}</h3>
        </div>
        <ArrowRightIcon className="h-3 w-3 text-slate-700 dark:text-slate-200" />
      </div>
    </div>
  );
};

const PromptCategoryList = ({ categories, handleInput }) => {
    return (
      <div className="grid w-full grid-flow-row grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2">
        {categories.map((category, index) => (
          <IndividualPromptComponent 
            key={index} 
            title={category.title} 
            category={category.category}
            Icon={category.Icon} 
            size={category.size} 
            handleInput={handleInput} 
            prompt={category.prompt}
          />
        ))}
      </div>
    );
};

const App = ({ handleInput }) => { 
  const categories = [
    {   
        title: "Creative Content",
        category: "creativeContent",
        Icon: LightBulbIcon,
        size: 3,
        prompt: "I need help with creating content."
    },
    {   
        title: "Business Strategy",
        category: "businessStrategy",
        Icon: ArrowTrendingUpIcon,
        size: 4,
        prompt: "I need help with creating a business strategy."
    },    
    {   
        title: "Tech Assistance",
        category: "techAssistance",
        Icon: CursorArrowRaysIcon,
        size: 4,
        prompt: "I need tech assistance."
    },
    {   
        title: "Code Generation",
        category: "codeGeneration",
        Icon: CodeBracketIcon,
        size: 4,
        prompt: "I need help with code generation."
    }          
  ];

  return <PromptCategoryList categories={categories} handleInput={handleInput} />;
};

export default App;