/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      owner
      userId
      value
      sexualScore
      sexualCategory
      cost
      conversationID
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      owner
      userId
      value
      sexualScore
      sexualCategory
      cost
      conversationID
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      type
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      owner
      userId
      value
      sexualScore
      sexualCategory
      cost
      conversationID
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      subject
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      flagged
      reviewed
      owner
      userId
      Messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      subject
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      flagged
      reviewed
      owner
      userId
      Messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      subject
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      flagged
      reviewed
      owner
      userId
      Messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createUserStats = /* GraphQL */ `
  mutation CreateUserStats(
    $input: CreateUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    createUserStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      date
      userId
      conversationCount
      messageCount
      totalCost
      owner
      __typename
    }
  }
`;
export const updateUserStats = /* GraphQL */ `
  mutation UpdateUserStats(
    $input: UpdateUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    updateUserStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      date
      userId
      conversationCount
      messageCount
      totalCost
      owner
      __typename
    }
  }
`;
export const deleteUserStats = /* GraphQL */ `
  mutation DeleteUserStats(
    $input: DeleteUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    deleteUserStats(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      date
      userId
      conversationCount
      messageCount
      totalCost
      owner
      __typename
    }
  }
`;
export const createAd = /* GraphQL */ `
  mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
    createAd(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      adType
      company
      adTitleText
      adDescriptionText
      adMediaUrl
      adDestinationUrl
      isActive
      __typename
    }
  }
`;
export const updateAd = /* GraphQL */ `
  mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
    updateAd(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      adType
      company
      adTitleText
      adDescriptionText
      adMediaUrl
      adDestinationUrl
      isActive
      __typename
    }
  }
`;
export const deleteAd = /* GraphQL */ `
  mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
    deleteAd(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      adType
      company
      adTitleText
      adDescriptionText
      adMediaUrl
      adDestinationUrl
      isActive
      __typename
    }
  }
`;
