import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "./ThemeProvider";
import "./index.css";
import AppStreamV2 from "./AppStreamV2"
import LandingPage from "./LandingPage";
import AccountSettingsPage from "./AccountSettingsPage";
import AdminPage from "./AdminPage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Auth } from "aws-amplify";
// const user = await Auth.currentAuthenticatedUser();

Sentry.init({
	dsn: "https://901ef1517fcb778e323b48370e0e51f0@o4506068651999232.ingest.sentry.io/4506307057680384",
	beforeSend(event, hint) {

		if (event.exception) {
		//   Sentry.showReportDialog({ eventId: event.event_id, labelName: "User ID", user: { name: user.username, email: user.attributes.email }});
		Sentry.showReportDialog({ eventId: event.event_id});
		}
		return event;
	  },
	integrations: [

	  new Sentry.BrowserTracing({
		tracePropagationTargets: ["localhost", "koshergpt.org", "https://dev7709.d2hc8m0zicanjf.amplifyapp.com", "https://dev.d2hc8m0zicanjf.amplifyapp.com "],
	}),
	  new Sentry.Replay(),
	  new Sentry.Feedback({
		colorScheme: "system",
		showBranding: false,
		showEmail: false,
		autoInject: false,
	  }),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	environment: process.env.NODE_ENV
  });
  

ReactDOM.createRoot(document.getElementById("root")).render(
	// <React.StrictMode>
		<ThemeProvider>
			<Router>
				<Routes>
					<Route path="/chat" element={<AppStreamV2 />} />
					<Route path="/chat/:conversationId" element={<AppStreamV2 />} />
					<Route path="/" element={<LandingPage />} />
					<Route path="/account" element={<AccountSettingsPage />} />
					<Route path="/admin" element={<AdminPage />} />
				</Routes>
			</Router>
		</ThemeProvider>
	// </React.StrictMode>
);

reportWebVitals();
