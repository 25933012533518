import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { API, Storage } from "aws-amplify";
import { UserCircleIcon } from '@heroicons/react/24/outline';
import UserCard from './UserCard';
import PricingPlans from './PricingPlans';
import { handleCreateBillingSession, handleCreateCheckoutSession } from './helpers/userCardHelpers';

const DropdownMenu = ({ onRename, onDelete, loading, dropdownRef }) => {
  return (
    <div ref={dropdownRef}  className="absolute z-[100]	right-0 top-8 mt-2 w-48 rounded-md bg-white shadow-lg">
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
         <div
          role="menuitem"
          className="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
          tabIndex="-1"
          data-orientation="vertical"
          data-radix-collection-item=""
          onClick={onRename}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md"><path fillRule="evenodd" clipRule="evenodd" d="M13.2929 4.29291C15.0641 2.52167 17.9359 2.52167 19.7071 4.2929C21.4783 6.06414 21.4783 8.93588 19.7071 10.7071L18.7073 11.7069L11.1603 19.2539C10.7182 19.696 10.1489 19.989 9.53219 20.0918L4.1644 20.9864C3.84584 21.0395 3.52125 20.9355 3.29289 20.7071C3.06453 20.4788 2.96051 20.1542 3.0136 19.8356L3.90824 14.4678C4.01103 13.8511 4.30396 13.2818 4.7461 12.8397L13.2929 4.29291ZM13 7.41422L6.16031 14.2539C6.01293 14.4013 5.91529 14.591 5.88102 14.7966L5.21655 18.7835L9.20339 18.119C9.40898 18.0847 9.59872 17.9871 9.7461 17.8397L16.5858 11L13 7.41422ZM18 9.5858L14.4142 6.00001L14.7071 5.70712C15.6973 4.71693 17.3027 4.71693 18.2929 5.70712C19.2831 6.69731 19.2831 8.30272 18.2929 9.29291L18 9.5858Z" fill="currentColor"></path></svg>
          Rename
        </div>
        <div
          role="menuitem"
          className="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group text-red-500"
          tabIndex="-1"
          data-orientation="vertical"
          data-radix-collection-item=""
          onClick={onDelete}
        >
          {loading ?<LoadingSpinner /> :
          <><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md"><path fillRule="evenodd" clipRule="evenodd" d="M10.5555 4C10.099 4 9.70052 4.30906 9.58693 4.75114L9.29382 5.8919H14.715L14.4219 4.75114C14.3083 4.30906 13.9098 4 13.4533 4H10.5555ZM16.7799 5.8919L16.3589 4.25342C16.0182 2.92719 14.8226 2 13.4533 2H10.5555C9.18616 2 7.99062 2.92719 7.64985 4.25342L7.22886 5.8919H4C3.44772 5.8919 3 6.33961 3 6.8919C3 7.44418 3.44772 7.8919 4 7.8919H4.10069L5.31544 19.3172C5.47763 20.8427 6.76455 22 8.29863 22H15.7014C17.2354 22 18.5224 20.8427 18.6846 19.3172L19.8993 7.8919H20C20.5523 7.8919 21 7.44418 21 6.8919C21 6.33961 20.5523 5.8919 20 5.8919H16.7799ZM17.888 7.8919H6.11196L7.30423 19.1057C7.3583 19.6142 7.78727 20 8.29863 20H15.7014C16.2127 20 16.6417 19.6142 16.6958 19.1057L17.888 7.8919ZM10 10C10.5523 10 11 10.4477 11 11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V11C13 10.4477 13.4477 10 14 10Z" fill="currentColor"></path></svg>
           Delete chat</>}
        </div>
      </div>
    </div>
  );
};

const AdminDropdownMenu = ({ onFlagForReview, onMarkAsReviewed, dropdownRef }) => {
  return (
    <div ref={dropdownRef} className="absolute z-[100] right-0 top-8 mt-2 w-48 rounded-md bg-white shadow-lg">
      {/* Add admin-specific menu items */}
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        {/* Flag for Additional Review */}
        <div
          role="menuitem"
          className="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
          tabIndex="-1"
          data-orientation="vertical"
          data-radix-collection-item=""
          onClick={onFlagForReview}
        >
        Flag for Additional Review
        </div>

        {/* Mark as Reviewed */}
        <div
          role="menuitem"
          className="flex gap-2 m-1.5 rounded p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5 dark:hover:bg-white/5 radix-disabled:pointer-events-none radix-disabled:opacity-50 group"
          tabIndex="-1"
          data-orientation="vertical"
          data-radix-collection-item=""
          onClick={onMarkAsReviewed}
        >
        Mark as Reviewed
        </div>
      </div>
    </div>
  );
};

const LoadingSpinner = ({ colors = "fill-red-600" }) => (

  <div className="text-center">

      <div role="status">
          <svg aria-hidden="true" 
                  className={`inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 ${colors}`} 

                  viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span className="sr-only">Loading...</span>
      </div>
  </div>
);

const Sidebar = ({ showMenu, user, group, isPlusGroup, isProGroup, conversationHistory, conversationHistoryLoading, handleReconnectChat, handleNewConversation, handleDeleteChat, deleteLoading, renameChat, fetchConversationHistory, handleCloseMenu, handleSignOut, handleToggleMenu, status, toggleTheme, theme, isPricingPlansVisible, handleTogglePricingPlans, totalConversations }) => {
  const [isUserCardVisible, setIsUserCardVisible] = useState(false);
  const [mediaUrls, setMediaUrls] = useState({});

  const [loading, setLoading] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const [editingConversationId, setEditingConversationId] = useState(null);
  const [newSubject, setNewSubject] = useState("");
  const [isRenaming, setIsRenaming] = useState(false); // State to track rename loading

  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    const fetchMediaUrls = async () => {
      let newMediaUrls = {};

      for (let conversation of conversationHistory) {
        if (conversation.__typename === "Ad" && (conversation.adType === "BANNER" || conversation.adType === "VIDEO")) {
          if (isUrl(conversation.adMediaUrl)) {
            newMediaUrls[conversation.adMediaUrl] = conversation.adMediaUrl;
          } else {
            const url = await Storage.get(conversation.adMediaUrl, {
              level: "public"
            });
            newMediaUrls[conversation.adMediaUrl] = url;
          }
        }
      }

      setMediaUrls(newMediaUrls);
    };

    fetchMediaUrls();
  }, [conversationHistory]);

  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    // If you want to immediately fetch conversations when the date is changed:
    if (group === "admin" || group == "filterOrg") {
      fetchConversationHistory(user, group, newDate);
    }
  };


  const handleDropdown = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      // console.log("Document clicked"); // Log to check if document click is captured
  
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // console.log("Click outside dropdown detected"); // Log when a click outside dropdown is detected
        setActiveDropdown(null);
      } else {
        // console.log("Click inside dropdown or ref not set"); // Log if click is inside dropdown or ref is not set
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    // console.log("Event listener added"); // Log when the event listener is added
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      // console.log("Event listener removed"); // Log when the event listener is removed
    };
  }, [dropdownRef]); 
  


  const handleRenameChat = (conversationId, currentSubject) => {
    setEditingConversationId(editingConversationId === conversationId ? null : conversationId);
    setNewSubject(currentSubject || "");
    setActiveDropdown(null);
  };

  const submitNewSubject = async (conversationId) => {
    console.log('Renaming started');
    setIsRenaming(true);
    try {
      await renameChat(conversationId, newSubject);
      setEditingConversationId(null);
    } catch (error) {
      console.error('Error updating subject:', error);
    } finally {
      setIsRenaming(false);
    }
  };
  
  const handleFlagForReview = (conversationId) => {
    console.log("Flagged for additional review");
    // Add your flagging logic here
  };

  const handleMarkAsReviewed = (conversationId) => {
    console.log("Marked as reviewed");
    // Add your marking logic here
  };

  

    // Determine the button text and plan status based on the group
    let planStatus;

    if (isProGroup) {
      planStatus = 'Pro';
    } else if (isPlusGroup) {
      planStatus = 'Plus';
    } else {
      if (group === 'new') {
      planStatus = 'New';
      } else {
      planStatus = 'Free';
      }
    }

  const toggleUserCard = () => {
    setIsUserCardVisible(!isUserCardVisible);
  };
  
  // Function to format date similar to the old component
  const formatDate = (dateString) => {
    const currentDate = moment().startOf('day');
    const conversationDate = moment(dateString);
  
    // Check for Today and Yesterday
    if (conversationDate.isSame(currentDate, 'day')) {
      // If today, return 'Today' and the time like '7:30 PM'
      return `Today - ${conversationDate.format('h:mm A')}`;
    } else if (conversationDate.isSame(currentDate.clone().subtract(1, 'days'), 'day')) {
      // If yesterday, return 'Yesterday' and the time
      return `Yesterday - ${conversationDate.format('h:mm A')}`;
    } else {
      // If not Today or Yesterday, return in "Jan 6 - 7:30 PM" format
      return conversationDate.format('MMM D - h:mm A'); // "MMM" for abbreviated month, "D" for day of the month, 'h:mm A' for time
    }
  };
  
  // Updated function to render history conversations with sorting
const renderHistoryConversations = () => {
  // Check if conversationHistory is available and an array
  if (!Array.isArray(conversationHistory)) {
    return <div>No Conversations Yet</div>;
  }

  const renderAd = (conversation) => {
    const destinationUrl = conversation.adDestinationUrl || '#';
    const mediaUrl = mediaUrls[conversation.adMediaUrl];

    switch (conversation.adType) {
      case "BANNER":
        return (
          <a href={ensureAbsoluteUrl(destinationUrl)} target="_blank" rel="noopener noreferrer"
          className="flex w-full items-center justify-between rounded-lg px-2 py-2 text-left transition-colors duration-200 hover:bg-slate-200 dark:hover:bg-slate-800"
          
          >
            
            {mediaUrl && <img src={mediaUrl} alt={conversation.adTitleText} />}
          </a>
        );

      case "VIDEO":
        return (
          <a href={ensureAbsoluteUrl(destinationUrl)} target="_blank" rel="noopener noreferrer"
          className="flex w-full items-center justify-between rounded-lg px-2 py-2 text-left transition-colors duration-200 hover:bg-slate-200 dark:hover:bg-slate-800"
          >
            {mediaUrl && <video controls>
              <source src={mediaUrl} type="video/mp4" />
            </video>}
          </a>
        );

      case "TEXT":
        return (
          <a href={ensureAbsoluteUrl(destinationUrl)} target="_blank" rel="noopener noreferrer"
          className="flex w-full flex-col gap-y-1 rounded-lg px-2 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
          >
            <h2 className="text-sm font-medium capitalize text-slate-700 dark:text-slate-200">
              {conversation.adTitleText}
            </h2>
            <p className="text-xs text-slate-500 dark:text-slate-400">
              {conversation.adDescriptionText}</p>
          </a>
        );

      default:
        return null;
    }
  };

  return conversationHistoryLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <LoadingSpinner colors="fill-slate-700 dark:fill-slate-200" />
    </div>
  ) : (
    conversationHistory.map((conversation, index) => {
      if (conversation.__typename === "Ad") {
        return (
        <div key={index} className="relative flex items-center justify-between">
          {renderAd(conversation)}
        </div>
        )
      } else {
        return (
   <div key={index} className="relative flex items-center justify-between">
        {editingConversationId === conversation.id ? (
          <>
          <input
            type="text"
            value={newSubject}
            onChange={(e) => setNewSubject(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') submitNewSubject(conversation.id);
            }}
            onBlur={() => submitNewSubject(conversation.id)}
            className="flex-1 rounded-lg p-2 text-sm"
            autoFocus
            disabled={isRenaming} // Disable input during loading
          />
          {isRenaming && <LoadingSpinner colors="fill-slate-700 dark:fill-slate-200" />}
        </>
        ) : (
        <button
      key={index}
      className="flex w-full flex-col gap-y-1 rounded-lg px-2 py-2 text-left transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800"
      onClick={() => handleReconnectChat(conversation.id)}
    >
      <h2 className="text-sm font-medium capitalize text-slate-700 dark:text-slate-200">
        {conversation.subject || conversation.id}
      </h2>
      <p className="text-xs text-slate-500 dark:text-slate-400">
        {formatDate(conversation.updatedAt)}
      </p>
    </button>
    )}
    {(group !== 'admin' && group !== 'filterOrg') && (

     <button
    className="ml-2 rounded-full p-2 hover:bg-slate-200 focus:outline-none dark:hover:bg-slate-800 text-slate-700  dark:text-slate-200"
    onClick={() => handleDropdown(conversation.id)}
  >
<svg width="24" height="24" viewBox="0 0 24 24"             stroke="currentColor"
            fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md relative"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z" fill="currentColor"></path></svg>
  </button> 
    )}
  {/* {activeDropdown === conversation.id && (
  group === 'admin' ? 
    <AdminDropdownMenu
      onFlagForReview={() => handleFlagForReview(conversation.id)}
      onMarkAsReviewed={() => handleMarkAsReviewed(conversation.id)}
      dropdownRef={dropdownRef}
    />
  : 
    <DropdownMenu
      onRename={() => handleRenameChat(conversation.id, conversation.subject)}
      onDelete={() => handleDeleteChat(conversation.id)}
      loading={deleteLoading}
      dropdownRef={dropdownRef}
    />
)} */}
  {activeDropdown === conversation.id &&
  
    <DropdownMenu
      onRename={() => handleRenameChat(conversation.id, conversation.subject)}
      onDelete={() => handleDeleteChat(conversation.id)}
      loading={deleteLoading}
      dropdownRef={dropdownRef}
    /> }

  </div>
        )
      }
    }));
};

function ensureAbsoluteUrl(url) {
  if (!url) return "#"; // or some default value
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return 'http://' + url; // Default to http if no scheme is provided
  }
}

  return (
    <>  
            {isPricingPlansVisible && (
              <div className="fixed m-0	my-0	mt-0	  inset-0 z-[100] lg:flex overflow-y-scroll	items-center justify-center bg-black/30"> {/* Overlay with light gradient */}

          <PricingPlans
            activePlanId={planStatus}
            loading={loading}
            setLoading={setLoading}
            handleCreateBillingSession={handleCreateBillingSession}
            handleCreateCheckoutSession={handleCreateCheckoutSession}
            onClose={handleTogglePricingPlans}
            user={user}
          />
          </div>
        )}
    <div className={`${showMenu && 'fixed z-[99]'} md:flex relative `}>
          <button
            className={`${showMenu ? 'top-0 left-56	' : ''} z-[999] absolute md:hidden p-3 animatedChar absolute`} 
            onClick={handleToggleMenu}
        >
          {showMenu ? (
      // "X" Icon (Close)
      <svg className='text-slate-700 dark:text-slate-200' viewBox="0 0 24 24" width="30" height="30" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    ) : (
      // Hamburger Icon (Menu)
      <svg className='text-slate-700 dark:text-slate-200' viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    )}
    </button>
    <div className={`${showMenu ?'fixed' : 'hidden'} md:flex`}>
<div className="flex-shrink-0 overflow-x-hidden h-full w-[260px]">
  <div className='h-full w-[260px]'>
    <div className='flex h-full min-h-0 flex-col'>
        <div className="flex h-full min-h-0 flex-col transition-opacity opacity-100">
            <div className='scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20'>

      <aside className={` z-50 md:relative md:flex`}>

      <div className={`flex  h-[100svh] w-60 flex-col overflow-y-auto bg-slate-50 dark:border-slate-700 dark:bg-slate-900 sm:h-[100vh] sm:w-64`}>
        {/* Logo */}
        
        {/* <div className="flex px-4 m-2">
        
       
                            <img  className="h-7 w-7 text-blue-600" src="/logo192.png" />
                            <h2 className="px-5 text-lg font-medium text-slate-800 dark:text-slate-200">
                              KosherGPT
                            </h2>
    
        </div> */}
 
        <div className="mx-2 mt-2">
        <button
            className={`flex w-full gap-x-4 items-center	 justify-between rounded-lg p-4 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:border-slate-700 dark:text-slate-200 dark:hover:bg-slate-800`}
            onClick={() => {
                handleNewConversation();
                handleCloseMenu();
            }}
            disabled={status === "loading"}
        >
          <div className='flex gap-2'>
             <img  className="h-7 w-7 text-blue-600" src="/logo192.png" />
                            <h2 className="text-lg font-medium text-slate-800 dark:text-slate-200">
                              KosherGPT
                            </h2>
                            </div>
            {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 5l0 14"></path>
            <path d="M5 12l14 0"></path>
            </svg> */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md"><path fillRule="evenodd" clipRule="evenodd" d="M16.7929 2.79289C18.0118 1.57394 19.9882 1.57394 21.2071 2.79289C22.4261 4.01184 22.4261 5.98815 21.2071 7.20711L12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16H9C8.44772 16 8 15.5523 8 15V12C8 11.7348 8.10536 11.4804 8.29289 11.2929L16.7929 2.79289ZM19.7929 4.20711C19.355 3.7692 18.645 3.7692 18.2071 4.2071L10 12.4142V14H11.5858L19.7929 5.79289C20.2308 5.35499 20.2308 4.64501 19.7929 4.20711ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V14C19 13.4477 19.4477 13 20 13C20.5523 13 21 13.4477 21 14V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34314 4.34315 3 6 3H10C10.5523 3 11 3.44771 11 4C11 4.55228 10.5523 5 10 5H6Z" fill="currentColor"></path></svg>
            {/* New Chat */}
        </button>
        </div>

        {(group === "admin" || group === "filterOrg") && (

       <div className="admin-date-picker">
       <label htmlFor="conversation-date">Select Date: </label>
       <input
         type="date"
         id="conversation-date"
         value={selectedDate}
         onChange={handleDateChange}
       />
       <div>Total Conversations: {totalConversations}</div>
     </div>
        )}
        {/* Conditional Rendering based on Loading State */}
{(!isPlusGroup && group !== "admin" && group !== "filterOrg") && 

        <button 
          onClick={handleTogglePricingPlans}
          className="flex w-full flex-row flex-wrap-reverse justify-between flex w-full gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium text-slate-700 transition-colors duration-200 hover:bg-slate-200 focus:outline-none dark:text-slate-200 dark:hover:bg-slate-800">
            <div className="flex items-center gap-2">
              <span className="flex h-7 w-7 items-center justify-center rounded-full border border-token-border-light ">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-sm shrink-0">
                  <path d="M8.78158 8.60266L9.8188 5.49098C10.037 4.83634 10.963 4.83634 11.1812 5.49098L12.2184 8.60266C12.7187 10.1035 13.8965 11.2813 15.3973 11.7816L18.509 12.8188C19.1637 13.037 19.1637 13.963 18.509 14.1812L15.3973 15.2184C13.8965 15.7187 12.7187 16.8965 12.2184 18.3973L11.1812 21.509C10.963 22.1637 10.037 22.1637 9.8188 21.509L8.78158 18.3973C8.28128 16.8965 7.10354 15.7187 5.60266 15.2184L2.49098 14.1812C1.83634 13.963 1.83634 13.037 2.49098 12.8188L5.60266 11.7816C7.10354 11.2813 8.28128 10.1035 8.78158 8.60266Z" fill="currentColor">
                    </path>
                    <path d="M17.1913 3.69537L17.6794 2.23105C17.7821 1.92298 18.2179 1.92298 18.3206 2.23105L18.8087 3.69537C19.0441 4.40167 19.5983 4.9559 20.3046 5.19133L21.769 5.67944C22.077 5.78213 22.077 6.21787 21.769 6.32056L20.3046 6.80867C19.5983 7.0441 19.0441 7.59833 18.8087 8.30463L18.3206 9.76895C18.2179 10.077 17.7821 10.077 17.6794 9.76895L17.1913 8.30463C16.9559 7.59833 16.4017 7.0441 15.6954 6.80867L14.231 6.32056C13.923 6.21787 13.923 5.78213 14.231 5.67944L15.6954 5.19133C16.4017 4.9559 16.9559 4.40167 17.1913 3.69537Z" fill="currentColor">
                      </path>
                      </svg>
                      </span>
                      <div className="flex flex-col">
                        <span className="font-semibold">
                          Upgrade
                          </span>
                          <span className="text-xs text-token-text-secondary line-clamp-1 w-full">
                            Get Full History, Unlimited messages, No ads, and more
                          </span>
                          </div>
                        </div>
                        {/* <span className="rounded-md bg-yellow-200 px-2 py-0.5 text-xs font-medium uppercase text-gray-800">
                          NEW
                        </span> */}
                        </button> 

                  }

                        <div className="h-3/4 space-y-3 overflow-y-auto border-b border-slate-300 px-2 py-4 dark:border-slate-700">
                        
                          {renderHistoryConversations()}
                        </div>
                       



        {/* User Section */}
        <div className={`flex flex-col items-center mt-auto w-full ${!isUserCardVisible && !isPricingPlansVisible ? 'space-y-4' : ''} px-2 py-4`}>
          {/* ... User button ... */}
          <button
          onClick={toggleUserCard}
          className="flex justify-between	 w-full items-center	 gap-x-2 rounded-lg px-3 py-2 text-left text-sm font-medium transition-colors duration-200 hover:bg-slate-200 focus:outline-none text-slate-700  dark:text-slate-200 dark:hover:bg-slate-800">

            <span className='flex items-center flex-row gap-x-2 '>
              {user && user.attributes.picture ? (
                <img src={user.attributes.picture} className='h-6 w-6' alt="Profile" />
              ) : (
                <UserCircleIcon className='h-6 w-6' strokeWidth="2" />
              )}

              <span>
              Hi {user && user.attributes.given_name ? (
                // Display given_name and family_name if available
                `${user.attributes.given_name} ${user.attributes.family_name ? user.attributes.family_name : ''}`
              ) : (
                // If no given_name, display the first letter of the email
                user && user.attributes.email ? user.attributes.email.charAt(0).toUpperCase() : ''
              )}
              </span>
              {/* Display a specific icon based on the isPlusGroup condition */}
              <span>{!isPlusGroup ? '♾️'  : '✨'}</span>
            </span>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-md relative"><path fillRule="evenodd" clipRule="evenodd" d="M3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12Z" fill="currentColor"></path></svg>

          </button>
         

        {isUserCardVisible && (
          <UserCard
            isUserCardVisible={isUserCardVisible}
            isPricingPlansVisible={isPricingPlansVisible}
            isPlusGroup={isPlusGroup}
            isProGroup={isProGroup}
            user={user}
            group={group}
            setIsPricingPlansVisible={handleTogglePricingPlans}
            onClose={toggleUserCard}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {/* 
        <>
        {isPricingPlansVisible && (
              <div className="fixed m-0	my-0	mt-0	  inset-0 z-50 lg:flex overflow-y-scroll	items-center justify-center bg-black/30">

          <PricingPlans
            activePlanId={planStatus}
            loading={loading}
            setLoading={setLoading}
            handleCreateBillingSession={handleCreateBillingSession}
            handleCreateCheckoutSession={handleCreateCheckoutSession}
            onClose={handleTogglePricingPlans}
            user={user}
          />
          </div>
        )}
        </>
         */}

          {/* Theme toggle buttons */}

          <div className="flex gap-2	 px-2 py-4 ml-3 text-sm font-medium text-slate-800 dark:text-slate-200">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="inline-flex h-4 w-4" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
            <label className="relative flex cursor-pointer items-center">
              <input type="checkbox" value="" className="peer sr-only" checked={theme === 'dark'}  onClick={toggleTheme} />
              <div
                className="peer h-6 w-11 rounded-full bg-slate-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-slate-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:border-slate-600 dark:bg-slate-700 dark:peer-focus:ring-blue-800"
              ></div>
              {/* <span className="ml-3 text-sm font-medium text-slate-800 dark:text-slate-200"
                >Toggle theme</span
              > */}
            </label>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline-flex h-4 w-4"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path></svg>


          </div>
            <button
            onClick={() => {
              handleSignOut();
            }}
            className="w-5/6 text-center items-center gap-x-2 rounded-lg bg-slate-700 px-3.5 py-2.5 text-center text-sm font-medium text-slate-50 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Sign Out
          </button>
        </div>
      </div>
    </aside>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </>
  );
};

export default Sidebar;
