/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      type
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      owner
      userId
      value
      sexualScore
      sexualCategory
      cost
      conversationID
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        owner
        userId
        value
        sexualScore
        sexualCategory
        cost
        conversationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByOwner = /* GraphQL */ `
  query MessagesByOwner(
    $owner: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByOwner(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        owner
        userId
        value
        sexualScore
        sexualCategory
        cost
        conversationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByUserId = /* GraphQL */ `
  query MessagesByUserId(
    $userId: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByUserId(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        owner
        userId
        value
        sexualScore
        sexualCategory
        cost
        conversationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByConversationID = /* GraphQL */ `
  query MessagesByConversationID(
    $conversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConversationID(
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        owner
        userId
        value
        sexualScore
        sexualCategory
        cost
        conversationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByDate = /* GraphQL */ `
  query MessagesByDate(
    $conversationID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      conversationID: $conversationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        owner
        userId
        value
        sexualScore
        sexualCategory
        cost
        conversationID
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      subject
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      flagged
      reviewed
      owner
      userId
      Messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByCreatedAt = /* GraphQL */ `
  query ConversationsByCreatedAt(
    $createdAt: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByCreatedAt(
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByIsFlagged = /* GraphQL */ `
  query ConversationsByIsFlagged(
    $flagged: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByIsFlagged(
      flagged: $flagged
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByIsReviewed = /* GraphQL */ `
  query ConversationsByIsReviewed(
    $reviewed: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByIsReviewed(
      reviewed: $reviewed
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByOwner = /* GraphQL */ `
  query ConversationsByOwner(
    $owner: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByOwner(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsByUserId = /* GraphQL */ `
  query ConversationsByUserId(
    $userId: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsByUserId(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchConversations = /* GraphQL */ `
  query SearchConversations(
    $filter: SearchableConversationFilterInput
    $sort: [SearchableConversationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableConversationAggregationInput]
  ) {
    searchConversations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        subject
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        flagged
        reviewed
        owner
        userId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserStats = /* GraphQL */ `
  query GetUserStats($id: ID!) {
    getUserStats(id: $id) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      date
      userId
      conversationCount
      messageCount
      totalCost
      owner
      __typename
    }
  }
`;
export const listUserStats = /* GraphQL */ `
  query ListUserStats(
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        date
        userId
        conversationCount
        messageCount
        totalCost
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userStatsByUserIdAndDate = /* GraphQL */ `
  query UserStatsByUserIdAndDate(
    $userId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatsByUserIdAndDate(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        date
        userId
        conversationCount
        messageCount
        totalCost
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userStatsByConversationCount = /* GraphQL */ `
  query UserStatsByConversationCount(
    $conversationCount: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatsByConversationCount(
      conversationCount: $conversationCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        date
        userId
        conversationCount
        messageCount
        totalCost
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userStatsByMessageCount = /* GraphQL */ `
  query UserStatsByMessageCount(
    $messageCount: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatsByMessageCount(
      messageCount: $messageCount
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        date
        userId
        conversationCount
        messageCount
        totalCost
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userStatsByTotalCost = /* GraphQL */ `
  query UserStatsByTotalCost(
    $totalCost: Float!
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatsByTotalCost(
      totalCost: $totalCost
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        date
        userId
        conversationCount
        messageCount
        totalCost
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAd = /* GraphQL */ `
  query GetAd($id: ID!) {
    getAd(id: $id) {
      id
      createdAt
      updatedAt
      customCreatedAt
      customUpdatedAt
      adType
      company
      adTitleText
      adDescriptionText
      adMediaUrl
      adDestinationUrl
      isActive
      __typename
    }
  }
`;
export const listAds = /* GraphQL */ `
  query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        adType
        company
        adTitleText
        adDescriptionText
        adMediaUrl
        adDestinationUrl
        isActive
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const adsByAdsType = /* GraphQL */ `
  query AdsByAdsType(
    $adType: AdType!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adsByAdsType(
      adType: $adType
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        adType
        company
        adTitleText
        adDescriptionText
        adMediaUrl
        adDestinationUrl
        isActive
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const adsByCompany = /* GraphQL */ `
  query AdsByCompany(
    $company: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adsByCompany(
      company: $company
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        adType
        company
        adTitleText
        adDescriptionText
        adMediaUrl
        adDestinationUrl
        isActive
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const adsByIsActiveAndUpdatedAt = /* GraphQL */ `
  query AdsByIsActiveAndUpdatedAt(
    $isActive: AdStatus!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adsByIsActiveAndUpdatedAt(
      isActive: $isActive
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        customCreatedAt
        customUpdatedAt
        adType
        company
        adTitleText
        adDescriptionText
        adMediaUrl
        adDestinationUrl
        isActive
        __typename
      }
      nextToken
      __typename
    }
  }
`;
