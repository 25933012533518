import React, { useEffect, useRef, useState } from 'react';

const LoadingSpinner = () => (
    <div className="text-center">

        <div role="status">
            <svg aria-hidden="true" className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
        </div>
    </div>
  );


const PricingPlans = ({
  user,
  activePlanId,
  loading,
  setLoading,
  handleCreateCheckoutSession,
  handleCreateBillingSession,
  onClose
}) => {
    const [activeButtonId, setActiveButtonId] = useState(null);
    const priceCardRef = useRef();

  const plans = [
    {
      id: "free",
      name: "Basic",
      description:
        "Enjoy usage of our standard service, perfect for one-off tasks.",
      trueFeatures: [
        "5 Messages Daily",
        "Conversation History - Limited",
        "Up to 1000 characters input per message",
        // "Ideal for one-off use",
      ],
      comingSoonFeatures: [
        "Speech to Text - 60 Seconds Monthly*"
    ],        
      falseFeatures: [
        "No Popup Ads",
        "Regenerate Responses",
        "AI Images",
        // "Ad Free",
        // "Regenerate Responses"
      ],      
      price: "Free*",
      label: "♾️ Free",
      isFeatured: false,
    },
    {
      id: "pro",
      name: "Pro",
      description:
        "Get everything in the Free plan, plus the ability to see all your past conversations and an ad-free experience.",
      trueFeatures: [
        "Unlimited Messages",
        "Unlimited Conversation History",
        "No Popup Ads",
        "Up to 4000 characters input per message",
      ],
      comingSoonFeatures: [
        "Speech to Text - 300 Seconds Daily*",
        "Regenerate Responses*",
    ],  
      falseFeatures: [
        "AI Images"
      ],   
      price: "$25*",
      label: "🚀 Most popular",
      isFeatured: activePlanId.toLowerCase() === "free",
    },
    {
      id: "plus",
      name: "Plus",
      description:
        "Embrace the full potential of our services with the Plus plan. Gain access to the advanced GPT-4 technology for more in-depth and sophisticated interactions, plus the ability to see all your past conversations.",
        trueFeatures: [
          "Unlimited Messages",
          "Unlimited Conversation History",
          "No Popup Ads",
          "Up to 4000 characters input per message",
      ],
      comingSoonFeatures: [
        "Speech to Text - 900 Seconds Daily*",
        "Regenerate Responses*",
        "AI Images*"
    ],      
      falseFeatures: [
      ],   
      price: "$50*",
      label: "🚀 Turbo Power",
      // isFeatured: activePlanId.toLowerCase() !== "free",
      isFeatured: true,
    },
    // {
    //   id: "enterprise",
    //   name: "Enterprise",
    //   description:
    //     "Embrace the full potential of our services with the Plus plan. Gain access to the advanced GPT-4 technology for more in-depth and sophisticated interactions, along with all the benefits of the Pro plan.",
    //   trueFeatures: [
    //     "Includes all free plan features.",
    //     "Access to advanced GPT-4 up to 300,000 tokens for more complex conversations.",
    //     "Unlimited access to your chat history",
    //     "Enjoy without any advertisements",
    //   ],
    //   price: "$20",
    //   label: "🚀 Customized",
    //   // isFeatured: activePlanId.toLowerCase() !== "free",
    //   isFeatured: false,
    // },
  ];


  const handleButtonClick = (planId) => {
    setActiveButtonId(planId); // Set the active button ID

    // Convert both planId and activePlanId to lowercase for comparison
    if (planId.toLowerCase() === activePlanId.toLowerCase()) {
      // console.log("handleCreateBillingSession", user, setLoading);
      handleCreateBillingSession(user, setLoading);
    } else {
      // console.log("handleCreateCheckoutSession", user, planId, setLoading);
      handleCreateCheckoutSession(user, planId, setLoading);
    }
};


  const getButtonLabel = (planId) => {
    if (planId === activePlanId) {
      return "Manage Plan";
    } else {
      switch (activePlanId) {
        case "Free":
          return "Upgrade";
        case "Pro":
          return planId === "Plus" ? "Upgrade" : "Downgrade"; // Corrected to show "Upgrade" for Plus plan
          case "Plus":
          return "Downgrade";
        default:
          return "Sign Up";
      }
    }
  };

  const handleClickOutside = (event) => {
    if (priceCardRef.current && !priceCardRef.current.contains(event.target)) {
      onClose();
    }
  };

    // Attach event listeners on mount and remove on unmount
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  return (
<div ref={priceCardRef} className="bg-slate-50 py-4 dark:bg-slate-900 max-w-xs sm:max-w-sm mx-auto md:max-w-md lg:max-w-none">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-200 sm:text-5xl">
            Pricing plans
          </h2>
          <p className="mt-2 text-xl tracking-tight text-slate-900 dark:text-slate-200 sm:text-5xl">
            Introductory pricing
          </p>          
        </div>
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-4xl	lg:grid-cols-3 lg:gap-x-4">
        {plans.map((plan) => {
          const buttonContent = loading && activeButtonId === plan.id ? 
                                <LoadingSpinner /> : 
                                getButtonLabel(plan.name);

          return (
            <div
              key={plan.id}
              className="flex flex-col justify-between rounded-3xl bg-slate-50 p-8 text-slate-900 ring-1 ring-slate-300 dark:bg-slate-900 dark:text-slate-200 dark:ring-slate-300/20 xl:p-10"
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id="tier-starter"
                    className="text-lg font-semibold leading-8"
                  >
                    {plan.name}
                  </h3>
                  <p className="rounded-full bg-blue-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-blue-600">
                    {plan.label}
                  </p>
                </div>
                {/* <p>{plan.description}</p> */}
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight">
                    {plan.price}
                  </span>

                  <span className="text-sm font-semibold leading-6 text-slate-700 dark:text-slate-400">
                    {plan.price === "Free" ? "" : "/month"}
                  </span>
                </p>

                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-slate-700 dark:text-slate-400"
                >
                  {plan.trueFeatures.map((feature, index) => (
                    <li key={index} className="flex gap-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-5 flex-none text-blue-600"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                        <path d="M9 12l2 2l4 -4"></path>
                      </svg>
                      {feature}
                    </li>
                  ))}
                  {plan.comingSoonFeatures.map((feature, index) => (
                    <li key={index} className="flex gap-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-5 flex-none text-blue-300">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                      {feature}
                    </li>
                  ))}                  
                  {plan.falseFeatures.map((feature, index) => (
                    <li key={index} className="flex gap-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-5 flex-none text-grey-600"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M18 6L6 18"></path>
                        <path d="M6 6l12 12"></path>
                      </svg>
                      <span className='line-through'>{feature}</span>
                    </li>
                  ))}                  
                </ul>
              </div>
              <button
                className={
                  plan.isFeatured
                    ? "mt-8 block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-blue-50 shadow-sm hover:bg-blue-700"
                    : "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 text-blue-600 ring-1 ring-inset ring-blue-600 hover:bg-blue-600 hover:text-blue-50"
                }
                onClick={() => handleButtonClick(plan.id)}
                disabled={loading && activeButtonId === plan.id || (plan.id === 'free' && activePlanId.toLowerCase() === 'free')}
                >
{plan.id === 'free' && activePlanId === 'Free' ? 'Active' : buttonContent}
              </button>
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
};

export default PricingPlans;