import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { identifyKlaviyoUser, trackKlaviyoEvent } from './components/utilities/klaviyoClient';
import "./LandingPage.css"; // Importing LandingPage CSS

function animateString(str, delayBase) {
  let array = str.split("").map((char, i) => (
    <span
      key={i}
      style={{ animationDelay: `${delayBase * i}s` }}
      className="animatedChar"
      dangerouslySetInnerHTML={{
        __html: char === " " ? "&nbsp;" : char,
      }}
    />
  ));
  array.push(
    <img
      key="logo"
      style={{ animationDelay: `${delayBase * str.length + 0.5}s` }}
      className="animatedCharImg"
      src="/logo192.png"
      alt="Logo"
    />
  );
  return array;
}

function LandingPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Track page view with detailed properties
    trackKlaviyoEvent('Page Viewed', {
      page_name: 'Landing Page',
      url: window.location.href,
      timestamp: new Date().toISOString(),
    });
  }, []);

  function handleSignUp() {
    // Track button click with detailed properties
    trackKlaviyoEvent('Button Clicked', {
      button_name: 'Sign Up Now',
      page_name: 'Landing Page',
      url: window.location.href,
      timestamp: new Date().toISOString(),
    });
    navigate("/chat");
  }

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      identifyKlaviyoUser(user, {
        signup_method: 'Cognito',
        signup_date: new Date().toISOString(),
      });
      navigate("/chat");
    } catch (error) {
      // console.log("User not logged in");
    }
  }

  return (
    <div className="landing text-slate-800 dark:text-slate-200 dark:bg-slate-800">
      <h1>{animateString("Welcome to KosherGPT", 0.1)}</h1>
      <p>
        A specially designed version of ChatGPT, providing safer content for our
        Jewish community.
      </p>
      <button id="signup" onClick={handleSignUp} 
        className="inline-flex items-center rounded-lg px-3 py-2 text-center text-sm font-semibold text-blue-600 ring-1 ring-inset ring-blue-600 hover:bg-blue-600 hover:text-blue-50">
        Start Chatting
      </button>
    </div>
  );
}

export default LandingPage;
