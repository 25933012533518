import { Amplify } from "aws-amplify";
import { trackKlaviyoEvent } from '../utilities/klaviyoClient';


export const handleCreateBillingSession = async (user, setLoading) => {
    setLoading(true);
    try {
        // Replace with your API Gateway endpoint
        const endpoint = 'https://lstgbqwwbf.execute-api.us-east-1.amazonaws.com/default/kosherGPT-connect-stripe-amplify';
        
        const userData = {
          username: user.username,
          userPoolId: user.pool.userPoolId,
          email: user.attributes.email,
          stripeCustomerId: user.attributes['custom:STRIPE_CUSTOMER_ID']
      };

    //   console.log("userData", userData);
    // Retrieve the Cognito session and get the ID token or access token
    const session = await Amplify.Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();   
    // console.log("idToken", idToken);  

      const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': idToken 
          },
          body: JSON.stringify({
              user: userData,
              type: 'create-stripe-billing-session'
          })
      });

        const data = await response.json();
        // console.log("Data", data);        

        if (data.session_url) {
            // Track the event of creating a billing session
            trackKlaviyoEvent('Billing Session Created', {
                user: user.username,
                email: user.attributes.email,
                stripeCustomerId: user.attributes['custom:STRIPE_CUSTOMER_ID'],
                timestamp: new Date().toISOString()
            });            
            // Redirect to Stripe session URL
            window.location.href = data.session_url;
        } else {
            // Handle error or no URL returned
            console.error('No session URL returned');
        }
    } catch (error) {
        console.error('Error creating billing session:', error);
    } finally {
        setLoading(false);
    }
};

export const handleCreateCheckoutSession = async (user, priceId, setLoading) => {
    setLoading(true);

    try {
        // Replace with your API Gateway endpoint
        const endpoint = 'https://lstgbqwwbf.execute-api.us-east-1.amazonaws.com/default/kosherGPT-connect-stripe-amplify';
        
        const userData = {
          username: user.username,
          userPoolId: user.pool.userPoolId,
          email: user.attributes.email,
          stripeCustomerId: user.attributes['custom:STRIPE_CUSTOMER_ID'],
          stripeLookupKey: priceId
      };
  
    //   console.log("userData", userData);
    // Retrieve the Cognito session and get the ID token or access token
    const session = await Amplify.Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();   
    // console.log("idToken", idToken);  
    
      const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': idToken 
          },
          body: JSON.stringify({
              user: userData,
              type: 'create-stripe-checkout-session'
          })
      });
  
        const data = await response.json();
        // console.log("Data", data);
        if (data.session_url) {
            // Track the event of creating a checkout session
            trackKlaviyoEvent('Checkout Session Created', {
                user: user.username,
                email: user.attributes.email,
                stripeCustomerId: user.attributes['custom:STRIPE_CUSTOMER_ID'],
                priceId: priceId,
                timestamp: new Date().toISOString()
            });            
            // Redirect to Stripe session URL
            window.location.href = data.session_url;
        } else {
            // Handle error or no URL returned
            console.error('No session URL returned');
        }
    } catch (error) {
        console.error('Error creating billing session:', error);
    } finally {
        setLoading(false);
    }
  };
    // Function to handle outside click
    export const handleClickOutside = (ref, onClose) => (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onClose();
        }
    };
  