import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { studioTheme, AdCreateForm } from './ui-components';

// Configure Amplify with the AWS exports
Amplify.configure(awsExports);

function AdminPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                console.log('user:', user);
                const groups = decodeIdTokenGroups(user.signInUserSession.idToken.jwtToken);
                if (groups.includes('admin')) {
                    // User is an admin, proceed
                } else {
                    // User is not an admin, redirect to the homepage
                    navigate('/');
                }
            } catch (err) {
                console.error('Error initializing:', err);
                navigate('/'); // Redirect if there's an error fetching the user
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [navigate]); // Depend on navigate to avoid re-running on every render

    function decodeIdTokenGroups(idToken) {
        const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
        return decodedToken['cognito:groups'] || [];
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="settings max-w-xl p-4 text-slate-800 dark:text-slate-200 dark:bg-slate-800">
            <h1 className="text-2xl font-semibold mb-4">Create Ad</h1>
            <AdCreateForm />
        </div>
    );
}

export default AdminPage;
